
// TODO: dynamic import to save bytes
import React from 'react'
import en from './en'
import zh from './zh'
import { getNormalizedUserLocale, NormalizeLocaleType } from '@/containers/LanguageProvider'

const mapping: Record<NormalizeLocaleType, React.ElementType> = {
  en,
  zh,
}

export default mapping[getNormalizedUserLocale()]
