import React, { useState } from 'react'
import styled from 'styled-components'
import { SettingOutlined } from '@/components/atom/Icons'
import {
  Menu,
} from '@/components'

interface WrapProps {
  isActive?: boolean
}

const SettingsWrap = styled.div<{ active: boolean }>`
  width: 30px;
  height: 30px;
  justify-content: center;
  position: relative
  align-items: center;
  font-size: 18px;
  color: #aaa;
  ${({ active }) => active ? 'color: #333;' : ''}
  &:hover {
    color: #333;
  }
`

const MenuWrap = styled.div`
  position: absolute;
  top: 30px;
  right: 10px;
  z-index: 2;
  border: 1px solid #ccc;
`

interface Props extends UIComponentProps {
  settingActions: any[]
}

const Settings = ({
  settingActions,
}: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  return (<SettingsWrap
    active={ isMenuOpen }
    onClick={ (e) => { e.stopPropagation() ;setIsMenuOpen(!isMenuOpen) }}
  >
    <SettingOutlined
      translate="1"
    />
    {
      isMenuOpen &&
      <MenuWrap>
        {
          <Menu>
            {
              settingActions.map(({ name, action }) => {
                return <Menu.Item
                  key={ name } onClick={ action }
                  style={{ height: 20, lineHeight: '20px' }}
                >  
                  { name }
                </Menu.Item>
              })
            }
          </Menu>
        }
      </MenuWrap>
    }
  </SettingsWrap>)
}

export default Settings
