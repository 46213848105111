import React from 'react'
import styled from 'styled-components'
import { Sizes } from '@/style/theme'

const Wrap = styled.div<{ flow: boolean }>`
  ${({ flow }) => flow ? '' : `width: ${Sizes.app.containerWidth}px;`}
  /* width: ${Sizes.app.containerWidth}px; */
  margin: 0 auto;
  position: relative;
`

interface Props {
  children: React.ReactNode,
  flow?: boolean
}

const Container = ({
  children,
  flow = false,
  ...rest
}: Props) => (
  <Wrap flow={flow} { ...rest }>
    { children }
  </Wrap>
)

export default Container