export const zh = {
  interval: '區間',
  periodEndNetAssets: '期末淨資產',
  actualNetAssets: '實際淨資產',
  userAssetsHint: '輸入您實際的淨資產，來追蹤是否跟上規劃',
  cancel: '取消',
  confirm: '確認',
  'moment.relative.time.future': '在 %s 後',
  'moment.relative.time.past':   '%s前',
  'moment.relative.time.s': '幾秒',
  'moment.relative.time.ss': '%d 秒',
  'moment.relative.time.m':  '1分鐘',
  'moment.relative.time.mm': '%d 分鐘',
  'moment.relative.time.h':  '1小時',
  'moment.relative.time.hh': '%d小時',
  'moment.relative.time.d':  '1天',
  'moment.relative.time.dd': '%d天',
  'moment.relative.time.M':  '1個月',
  'moment.relative.time.MM': '%d個月',
  'moment.relative.time.y':  '1年',
  'moment.relative.time.yy': '%d年',
  'feedback.verify-email.sent': '驗證信已送出至 {{email}}',
  'error.try-later': '發生錯誤，請稍候再試',
}

export const en = {
  interval: 'interval',
  periodEndNetAssets: 'net assets at period end',
  actualNetAssets: 'Actual net assets',
  userAssetsHint: 'Please enter your net asset to trace if you can meet the plan.',
  cancel: 'Cancel',
  confirm: 'Confirm',
  'moment.relative.time.future': 'after %s',
  'moment.relative.time.past':   '%s before',
  'moment.relative.time.s': 'a few seconds',
  'moment.relative.time.ss': '%d seconds',
  'moment.relative.time.m':  '1 minute',
  'moment.relative.time.mm': '%d minutes',
  'moment.relative.time.h':  '1 hour',
  'moment.relative.time.hh': '%d hours',
  'moment.relative.time.d':  '1 day',
  'moment.relative.time.dd': '%d days',
  'moment.relative.time.M':  'a month',
  'moment.relative.time.MM': '%d months',
  'moment.relative.time.y':  'a year',
  'moment.relative.time.yy': '%d years',  
  'error.try-later': 'Something wrong happened',
}
