import React from 'react'
import styled from 'styled-components'
import { Tag, Divider } from '@/components'

const Wrap = styled.div``

const Title = styled.h3`
  margin-bottom: 10px;
  padding-bottom: 2px;
  font-weight: bold;
  font-size: 13px;
  border-bottom: 1px solid #eee;
`

const Content = styled.p``

const List = styled.ul`
  margin: 10px  0;
  padding-left:20px;
`
const ListItem = styled.li`
`

const FootNote = styled.span`
  font-size: 13px;
  color: #555;
`

const CodeBlock = styled.pre`
  margin-top: 10px;
  padding: 8px 15px;
  background-color: #f5f5f5;
`

const Math1 = `20,000 * 1.05^(31/366)
10,000 * 1.05^(31/366) ≒ (net cashflow at the beginning of 2020-01)
----------------------
30,124

Note:
1. 2020 is leap year, there are 366 days. 
2. There are 31 days in January.
`

const Math2 = `20,000 * 1.05 +
10,000 * 1.05 ≒ (net cashflow at the beginning of 2020-01)
-------------
31,500
`

const NetAssetsCalculateInstruction = () => {
  return (
    <Wrap>
      <Title>
        How it calculated?
      </Title>
      <Content>
        <List>
          <ListItem>
            Net assets is the amount at the <strong>end</strong> of the period<br />
            <FootNote>ex: Net assets of 2020-03 happened at 2020-03-31 23:59:59</FootNote>
          </ListItem>
          <ListItem>
            All the incomes and expenditures happened at the <strong>start</strong> or the period <br/>
            <FootNote>ex: Income of 2020-03 happened at 2020-03-01 00:00:00</FootNote>
          </ListItem>
          <ListItem>
            When the net assets is negative, the accumulated direction is negative too.
          </ListItem>
        </List>
      </Content>
      <Title>
        Examples:
      </Title>
      <Content>
        Jack had 20,000 dollars at the end of 2019-12, <br />
        Annual return on the net assets is 5%, <br />
        and he had an 30,000 income and an 20,000 expenditure at the beginning of 2020-01: 
        <Divider />
        <Tag>His has 30,124 net assets at the end of <strong>2020-01</strong>, calculation details as follow:</Tag>
        <CodeBlock>
          { Math1 }
        </CodeBlock>
        <Tag>His has 31,500 net assets at the end of <strong>2020</strong>, calculation details as follow:</Tag>
        <CodeBlock>
          { Math2 }
        </CodeBlock>
        
      </Content>
    </Wrap>
  )
}

export default NetAssetsCalculateInstruction
