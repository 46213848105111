import moment, { Moment } from 'moment'
import { AnnuityFreq } from './Annuity'

type MomentUnit = 'year' | 'month' | 'day'

interface GetSlotKeyArgs {
  slotType: AnnuityFreq
}

const getYearMs = (m: Moment) => m.isLeapYear()
  ? 31622400000
  : 31536000000

// const PortionMap: any = {
//   '01': 1,
//   '02': 0.9166666667,
//   '03': 0.8333333333,
//   '04': 0.75,
//   '05': 0.6666666667,
//   '06': 0.5833333333,
//   '07': 0.5,
//   '08': 0.4166666667,
//   '09': 0.3333333333,
//   '10': 0.25,
//   '11': 0.1666666667,
//   '12': 0.08333333333,
// }

export default class Time {
  static UnitMap = {
    [AnnuityFreq.YEAR]: 'year',
    [AnnuityFreq.MONTH]: 'month',
    [AnnuityFreq.DAY]:  'day',
    [AnnuityFreq.TS]:  'ts',
  }
  public moment: Moment;
  constructor(...args: any[]) {
    this.moment = moment(...args)
  }
  public getSlotKey({
    slotType = AnnuityFreq.TS,
  }: GetSlotKeyArgs): any {
    const strategy = {
      [AnnuityFreq.YEAR]: this.getSlotKeyYear.bind(this),
      [AnnuityFreq.MONTH]: this.getSlotKeyMonth.bind(this),
      [AnnuityFreq.DAY]: this.getSlotKeyDate.bind(this),
      [AnnuityFreq.TS]: this.getSlotKeyTS.bind(this),
    }[slotType]
    return strategy()
  }

  public increment(nth: number, freq: AnnuityFreq): Time {
    const clone = moment(this.moment)
    clone.add(
      nth,
      this.getFreqForMoment(freq),
    )
    return new Time(clone)
  }
  public getEndOfYear() {
    const clone = moment(this.moment)
    clone.endOf('year')
    return new Time(clone)
  }
  public getEndOfMonth() {
    const clone = moment(this.moment)
    clone.endOf('month')
    return new Time(clone)
  }

  private getSlotKeyTS () {
    return this.moment.valueOf()
  }
  private getSlotKeyDate () {
    return this.moment.format('YYYY-MM-DD')
  }
  private getSlotKeyMonth () {
    return this.moment.format('YYYY-MM')
  }
  private getSlotKeyYear () {
    return this.moment.format('YYYY')
  }

  public getTs(){
    return this.moment.valueOf()
  }

  public diffYear(time: Time) {
    return this.moment.diff(time.moment) / getYearMs(this.moment)
  }
  public distToEndOfYear(): number {
    // return PortionMap[this.moment.format('MM') as string]    
    const clone = moment(this.moment)
    const yearEnd = clone.endOf('year')
    const ret = -this.moment.diff(yearEnd) / getYearMs(this.moment)

    return ret
  }
  public distToEndOfMonth(): number {
    const clone = moment(this.moment)
    const month = clone.endOf('month')
    const ret = -this.moment.diff(month) / getYearMs(this.moment)

    return ret
  }
  public distTStartOfYear(): number {
    const clone = moment(this.moment)
    const year = clone.startOf('year')
    const ret = this.moment.diff(year) / getYearMs(this.moment)

    return ret
  }
  public getFormat (){} // (moment format)
  public fromSlotKey(){}//(getSlotKey)
  public fromTs(){}
  public fromDate(){}// (JS Date)

  private getFreqForMoment(freq: AnnuityFreq): MomentUnit {
    const ret = Time.UnitMap[freq] as MomentUnit
    return ret || 'year'
  }
}


