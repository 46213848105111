import React from 'react'
import styled from 'styled-components'
import { Tag, Divider } from '@/components'

const Wrap = styled.div``

const Title = styled.h3`
  margin-bottom: 10px;
  padding-bottom: 2px;
  font-weight: bold;
  font-size: 13px;
  border-bottom: 1px solid #eee;
`

const Content = styled.p``

const List = styled.ul`
  margin: 10px  0;
  padding-left:20px;
`
const ListItem = styled.li`
`

const FootNote = styled.span`
  font-size: 13px;
  color: #555;
`

const CodeBlock = styled.pre`
  margin-top: 10px;
  padding: 8px 15px;
  background-color: #f5f5f5;
`

const Math1 = `20,000 * 1.05^(31/366)   (2020年期末淨資產累積)
10,000 * 1.05^(31/366) ≒ (2020年1月收支累積)
----------------------
30,124

註: 2020年為閏年有366天，1月有31天
`

const Math2 = `20,000 * 1.05 + (2020年期末淨資產累積)
10,000 * 1.05 ≒ (2020年1月收支累積)
-------------
31,500
`

const NetAssetsCalculateInstruction = () => {
  return (
    <Wrap>
      <Title>
        計算說明
      </Title>
      <Content>
        <List>
          <ListItem>
            淨資產皆為該期累積至<strong>期末</strong>的金額 <br />
            <FootNote>ex: 2020-03 的淨資產，時間為 2020-03-31 23:59:59</FootNote>
          </ListItem>
          <ListItem>
            所有收入及支出皆發生在<strong>期初</strong> <br/>
            <FootNote>ex: 2020-03 的收入，發生時間為 2020-03-01 00:00:00</FootNote>
          </ListItem>
          <ListItem>
            當淨資產為負值時，淨資產的累積亦為負值
          </ListItem>
        </List>
      </Content>
      <Title>
        範例:
      </Title>
      <Content>
        小明於 <strong>2019-12</strong> 期末擁有 <strong>20,000</strong> 元淨資產 <br />
        淨資產的年化報酬率為 <strong>5%</strong> <br />
        並於 <strong>2020-01</strong> 期初有: <br />
        <List>
          <ListItem>一筆 <strong>30,000</strong> 元的收入</ListItem>
          <ListItem>一筆 <strong>20,000</strong> 元的支出</ListItem>
        </List>
        <Divider />
        <Tag>於 <strong>2020-01</strong> 期末的淨資產為 30,124 元，計算如下：</Tag>
        <CodeBlock>
          { Math1 }
        </CodeBlock>
        
        <Tag>於 <strong>2020</strong> 期末的淨資產為 31,500 元，計算如下：</Tag>
        <CodeBlock>
          { Math2 }
        </CodeBlock>
        
      </Content>
    </Wrap>
  )
}

export default NetAssetsCalculateInstruction
