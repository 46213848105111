import React from 'react'
import moment from 'moment'
import { arrayOf, object, string } from 'prop-types'
import * as d3 from 'd3'
import styled from 'styled-components'

import { AnnuityFreq } from '@/services/core/Annuity'
import { WIDTH, HEIGHT, Margin } from './layout'
import { formatCurrency } from '@/utils'

const Wrap = styled.div`
  .hoverBox {
    stroke: #ddd;
    stroke-width: 1px;
  }
  .tooltip-parent {
    transition: opacity .1s;
    .title {
      font-size: 12px;
      stroke: #333;
      fill: #333;
      transform: translateX(-50px);
    }
    .line1 {
      fill: #888;
      font-size: 12px;
    }
  }
  .domain {
    display: none;
  }
`

export function hexToRgba(hex, alpha = 0.5) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})`
}

const DURATION = 600

// 轉 收的 props  update
class Chart {
  constructor(node, data, freq, config) {
    this.layout = {
      margin: Margin,
      height: HEIGHT,
      width: WIDTH,
    }
    this.config = config
    this.freq = freq
    this.node = node
    this.svg = null
    this.data = data
    this.xExtent = null
    this.xScale = null
    this.xAxis = null
    this.yMax = null
    this.yMin = null
    this.yScale = null
    this.yAxis = null
    this.area = null
    this.line = null
    this.pathArea = null
    this.pathLine = null
    this.isTransition = true
  }
  initSvg() {
    const {
      width,
      height,
    } = this.layout
    this.svg = d3.create('svg')
      .attr('viewBox', [0, 0, width, height])

    this.svg
      .on('mousemove', (e) => this.handleMouseover(e, this))
      .on('mouseleave', (e) => this.handleMouseLeave(e, this))
    this.node.append(this.svg.node())
  }

  initD3Element () {
    const { data, layout } = this
    const {
      width,
      height,
      margin,
    } = layout

    this.xExtent = d3.extent(data, d => d.date)

    this.xScale = d3.scaleTime()
      .domain(this.xExtent)
      .range([margin.left, width - margin.right])

    this.yMax = d3.max(data, d => d.value)
    this.yMin = d3.min(data, d => d.value)
    // const yMin = 0
    this.yScale = d3.scaleLinear()
      .domain([this.yMin, this.yMax])
      .range([height - margin.bottom, margin.top])

    this.area = d3.area()
      .x(d => this.xScale(d.date))
      .y1(d => this.yScale(d.value))
      .y0(this.yScale(this.yMin <= 0 ? 0 : this.yMin))

    this.line = d3.line()
      .x(d => this.xScale(d.date))
      .y(d => this.yScale(d.value))
  
  }

  initArea() {
    const emptyData = this.data.map((d) => ({ ...d, value: 0 }))
    this.pathArea = this.svg.append('path')
      .attr('d', this.area(emptyData))
      .attr('stroke', this.config.chartColor)
      .attr('stroke-width', this.yMin >= 0 ? '0' : '2px')
      .attr('fill', hexToRgba(this.config.chartColor, 0.3))
    
    this.pathLine = this.svg.append('path')
      .attr('d', this.line(emptyData))
      .attr('stroke', this.config.chartColor)
      .attr('stroke-width', '2px')
      .attr('fill', 'none')
  }

  prepareArea () {
    if (!this.pathArea || !this.pathLine) {
      this.initArea()
    }
    this.pathArea
      .transition()
      .duration(this.isTransition ? DURATION : 0)
      .ease(d3.easeSin)
      .attr('d', this.area(this.data))
    this.pathLine
      .transition()
      .duration(this.isTransition ? DURATION : 0)
      .ease(d3.easeSin)
      .attr('d', this.line(this.data))
  }
  
  initAxis() {
    const { layout, xScale, yScale, freq, data }  = this
    const { margin, width, height } = layout

    const drawAreaWidth = width - margin.left - margin.right

    this.yAxis = g => g
      .attr('transform', `translate(${margin.left + drawAreaWidth}, 0)`)
      .call(
        d3.axisLeft(yScale)
          .ticks(null, 's')
          .tickSize(drawAreaWidth),
      )
      .call(g => g.selectAll('.domain').remove())
      .call(g => g.selectAll('.tick line')
        .attr('stroke-opacity', 0.2)
        .attr('stroke-dasharray', '2,2'),
      )
      .call(g => g.selectAll('.tick text')
        .attr('color', '#999'),
      )
    this.xAxis = g => g
      .attr('transform', `translate(0, ${height-margin.bottom})`)
      .call(
        d3.axisBottom(xScale)
          .ticks()
          .tickFormat(d => freq === AnnuityFreq.YEAR
            ? data.length < 3
              ? moment(d).add(-1, 'months').format('YYYY-MM')
              : moment(d).add(-1, 'years').format('YYYY')
            : moment(d).add(-1, 'months').format('YYYY-MM'),
          ),
      )
      .call(g => g.selectAll('.domain').remove())
      .call(g => g.selectAll('.tick text')
        .attr('color', '#999'),
      )
      .call(g => g.selectAll('.tick line')
        .attr('opacity', '0'),
      )
  }

  appendAxis() {
    const { xAxis, svg, yAxis } = this
    
    if (this.xAxisG) {
      this.xAxisG.transition()
        .duration(DURATION)
        .ease(d3.easeSin)
        .call(xAxis)
    } else {
      this.xAxisG = svg.append('g')
        .call(xAxis)
    }
    
    if (this.yAxisG) {
      this.yAxisG.transition()
        .duration(DURATION)
        .ease(d3.easeSin)
        .call(yAxis)
    } else {
      this.yAxisG = svg.append('g')
        .call(yAxis)
    }
  }

  drawMarker() {
    // this.tooltip.append('line').classed('hoverLine', true)
  }

  initTooltip() {
    const { width, height } = this.layout

    this.tooltip && this.tooltip.remove()

    this.tooltip = this.svg.append('g').classed('tooltip-parent', true)
    
    this.tooltip.append('line').classed('hoverLine', true)
    this.tooltip.append('circle').classed('hoverPoint', true)

    this.tooltip
      .append('rect')
      .classed('hoverBox', true)

    const text = this.tooltip.append('text').classed('hoverText', true)
    text.append('tspan').classed('title', true)

    text.append('tspan').classed('line1', true)
  
    this.hoverArea && this.hoverArea.remove()
    this.hoverArea = this.svg.append('rect')
      .attr('fill', 'transparent')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height)
    
  }

  hideTooltip() {
    this.tooltip.attr('opacity', 0)
  }
  showTooltip() {
    this.tooltip.attr('opacity', 1)
  }

  handleMouseLeave(e, self) {
    self.tooltip.attr('opacity', 0)
  }

  handleMouseover(e, self) {
    const {
      data,
      xScale,
      layout,
      freq,
      yScale,
      svg,
    } = self
    const {
      width,
      height,
      margin,
    } = layout
    this.showTooltip()

    d3.event.preventDefault()
    const mouse = d3.mouse(d3.event.target)
    const [ xCoord ] = mouse
  
    const mouseDate = xScale.invert(Math.max(xCoord, margin.left))

    let mouseDateSnap
    if (freq === AnnuityFreq.MONTH) {
      mouseDateSnap = d3.timeMonth.floor(mouseDate)
    } else {
      mouseDateSnap = d3.timeYear.floor(mouseDate)
    }

    // if (data[0].date > mouseDate) return

    const xScaleMouseDateSnapTmp = xScale(mouseDateSnap)
    
    // 15 is a hack offset, in order to hover the first data point
    // if (xScaleMouseDateSnapTmp < margin.left - 0 ||
    //    xScaleMouseDateSnapTmp > width - margin.right) {
    //   return
    // }
    
    const bisectDate = d3.bisector(d => d.date).left
    const xIndex = bisectDate(data, mouseDateSnap)

    if (!data[xIndex]) return

    const mouseValue = data[xIndex].value
    const yScaleMouseValue = yScale(mouseValue)
    const xScaleMouseDateSnap = xScale(data[xIndex].date)
  
    svg.selectAll('.hoverLine')
      .attr('x1', xScaleMouseDateSnap)
      .attr('y1', margin.top)
      .attr('x2', xScaleMouseDateSnap)
      .attr('y2', height - margin.bottom)
      .attr('stroke', '#ccc')
      .attr('fill', '#A6E8F2')
    
  
    svg.selectAll('.hoverPoint')
      .attr('cx', xScaleMouseDateSnap)
      .attr('cy', yScaleMouseValue)
      .attr('r', 3)
      .attr('stroke', this.config.chartColor)
      .attr('stroke-width', 2)
      .attr('fill', '#fff')
    

    const boxHeight = 40
    const boxWidth = 110
    const boxMarginX = 10
    const boxPadding = 5
    const textLineHeight = 18

    const state = [
      {
        targetX: xScaleMouseDateSnap + boxMarginX,
      },
      {
        targetX: xScaleMouseDateSnap - boxMarginX - boxWidth,
      },
    ]

    const isLessThanHalf = xIndex > data.length / 2
    const currentState = state[+isLessThanHalf]
    const {
      targetX,
    } = currentState

  
    const text = svg.selectAll('.hoverText')
      .attr('x', targetX)
      .attr('y', yScaleMouseValue - boxPadding + boxHeight / 2)

    text.selectAll('.title')
      .text(
        freq === AnnuityFreq.MONTH
          ? `${mouseDateSnap.getFullYear()}-${(mouseDateSnap.getMonth() + 1).toString().padStart(2, '0')}`
          : mouseDateSnap.getFullYear()
        ,
      )
      .attr('dx', 6)
      .attr('dy', -18)

    text.selectAll('.line1')
      .text(
        // d3.format('.5s')(mouseValue),
        formatCurrency('en', mouseValue, { decimalPlace: 0 }),
      )
      .attr('width', 2)
      .attr('dx', -28)
      .attr('dy', textLineHeight)

    svg.selectAll('.hoverBox')
      .attr('x', targetX)
      .attr('y', yScaleMouseValue - boxHeight / 2)
      .attr('width', boxWidth)
      .attr('height', boxHeight)
      .attr('fill', '#fff')
  }

  update(data, freq) {
    this.data = data
    this.freq = freq
    this.initD3Element()
    this.prepareArea()
    this.initAxis()
    this.appendAxis()
    this.initTooltip()
  }

  render() {
    this.initSvg()
    this.initD3Element()
    this.prepareArea()
    this.initAxis()
    this.appendAxis()
    this.initTooltip()
  }
}

class StackedBarChart extends React.Component {
  static propTypes = {
    data: arrayOf(object),
    colorRange: arrayOf(string),
    chartColor: string,
    freq: string,
  }
  constructor() {
    super()
    this.state = {
      id: 'area-chart-' + Math.random().toString().slice(2, 10),
    }
  }
  componentDidMount() {
    this.draw(this.props.data)
  }
  
  componentDidUpdate(prevProp) {
    if (prevProp.data.length !== this.props.data.length) {
      this.chart.isTransition = false
    } else {
      this.chart.isTransition = true
    }
    this.draw(this.props.data)
  }

  draw(data) {
    const  {
      freq,
      chartColor,
    } = this.props
    if (this.chart) {
      this.chart.update(data, freq)
    } else {
      this.chart = new Chart(
        document.getElementById(this.state.id),
        data,
        freq,
        {
          colorRange: this.props.colorRange,
          chartColor,
        },
      )
      this.chart.render()
    }
  }

  render() {
    return (
      <Wrap
        id={ this.state.id }
        style={{ width: '100%' }}
      />
    )
  }
}

export default StackedBarChart
