import { observable, action } from 'mobx'
import set from 'lodash/set'
import { RootStore } from './RootStore'

interface StoryEditState {
  isEditing: boolean
}

export default class EditState {
  constructor(
    public rootStore: RootStore,
  ) {}
  @observable public story: StoryEditState = {
    isEditing: false,
  }
  
  @action
  update(path: string, payload: any) {
    set(this, path, payload)
  }

  setStoryDirty() {
    this.update('story.isEditing', true)
  }
  cleanStoryDirty() {
    this.update('story.isEditing', false)
  }

}

