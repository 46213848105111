// TODO: refactor from firebase logic
// Notification i18n
import FirestoreUsers from '@/firebase/FirestoreUsers'

export const fetchUser = async (userId: string) =>  {
  const querySnapshot = await FirestoreUsers.getOne(userId)

  let data: any = null
  querySnapshot.forEach((doc: any) => {
    data = doc.data()
  })
  return { data }
}

export const updateUser: any = async (user: SavedUser) => {
  if (!user.id)  {
    throw new Error('updateStory is not allow without story id')
  }
  await FirestoreUsers.update({
    ...user,
  })

  return { data: user.id }

}

export const updateMergeUser: any = async (user: SavedUser) => {
  if (!user.id)  {
    throw new Error('updateStory is not allow without story id')
  }
  await FirestoreUsers.updateMerge({
    ...user,
  })

  return { data: user.id }

}
