import Loadable from 'react-loadable'
import { PageLoading } from '@/components'

const VersionLoadable = Loadable({
  loader: () => import('./'),
  loading: PageLoading,
})


export default VersionLoadable
