import { v4 as uuidv4 } from 'uuid'
import set from 'lodash/set'
import { observable, action } from 'mobx'
import { AnnuityMArgs, AnnuityFreq } from './../services/core/Annuity'
import { RootStore, EntityCRUD, EntityStore, UpdatePayload } from './RootStore'

interface AnnuityEntities {
  [id: string]: AnnuityMArgs
}

interface CreatePayload {
  id?: string
  n: number
  amount: number
  startAt: string
  freq: AnnuityFreq
}

export default class Annuity implements EntityStore, EntityCRUD {
  constructor(
    public rootStore: RootStore,
  ) {}
  @observable public entities: AnnuityEntities = {}
  @observable public byOrder: string[] = []

  @action
  update(path: string, payload: UpdatePayload) {
    const { id, value } = payload
    set(this.entities[id], path, value)
    this.rootStore.editState.setStoryDirty()
  }

  @action
  create(payload: CreatePayload) {
    const id = payload.id || uuidv4()
    this.entities[id] = {
      ...payload,
      id,
    }
    this.rootStore.editState.setStoryDirty()
    return id
  }

  @action
  remove(payload: UpdatePayload) {
    const { id } = payload
    Reflect.deleteProperty(this.entities, id)
    this.rootStore.editState.setStoryDirty()
  }

  from() {}
  to() {}
  merge() {}

  @action
  applyOptimization() {
    const { optimization } = this.rootStore
    const {
      aid,
      fieldName,
      value,
    } = optimization.optInfo
    this.update(fieldName, { id: aid, value })
    optimization.resetOptInfo()
  }
}
