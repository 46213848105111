import { observable, action } from 'mobx'
import set from 'lodash/set'
import { v4 as uuidv4 } from 'uuid'

import { EntityCRUD, RootStore, EntityStore, UpdatePayload, denormalizeAll } from './RootStore'
import { AnnuitySetMArgs } from './../services/core/AnnuitySet'

interface AnnuityEntities {
  [id: string]: AnnuitySetMArgs<AnnuityId>
}

type AnnuityId = string

interface CreatePayload {
  id?: string
  name: string
  desc?: string
  holdings?: string[]
}

export default class AnnuitySet implements EntityStore, EntityCRUD {
  constructor(
    public rootStore: RootStore,
  ) {}
  @observable public entities: AnnuityEntities = {}
  @observable public byOrder: string[] = []

  @action
  update(path: string, payload: UpdatePayload) {
    const { id, value } = payload
    set(this.entities[id], path, value)
    this.rootStore.editState.setStoryDirty()
  }

  @action
  create({ name, id = uuidv4(), holdings = [] }: CreatePayload) {
    this.entities[id] = {
      id,
      name,
      holdings,
    }
    this.rootStore.editState.setStoryDirty()
    return id
  }

  @action
  remove(payload: UpdatePayload) {
    const { id } = payload
    Reflect.deleteProperty(this.entities, id)
    this.rootStore.editState.setStoryDirty()
  }

  addHoldings(asId: string, aId: string) {
    this.update('holdings', {
      id: asId,
      value: [...this.entities[asId].holdings, aId],
    })
    this.rootStore.editState.setStoryDirty()
  }

  removeHoldings(asId: string, aId: string) {
    this.update('holdings', {
      id: asId,
      value: this.entities[asId].holdings.filter(id => id !== aId),
    })
    this.rootStore.editState.setStoryDirty()
  }

  from() {}
  to() {}
  merge() {}

  // denormalizeOne() {
  //   return denormalize(this)
  // }

  denormalizeAll() {
    return denormalizeAll(this.entities, 'holdings', 'annuity')
  }
}
