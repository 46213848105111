import React from 'react'
import { RootStore } from './RootStore'

export const store = new RootStore()
if (process.env.NODE_ENV === 'development') {
  window.$store = store
}

const storesContext = React.createContext({
  rootStore: store,
})

export const useStore = () => {
  const {
    rootStore,
  } = React.useContext(storesContext)

  return rootStore
}