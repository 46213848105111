import { observable } from 'mobx'
import { RootStore } from './RootStore'

interface OptInfo {
  aid?: string
  fieldName?: string,
  displayName?: string,
  value?: number
  isOptimal?: boolean
  isReachedMaxTry?: boolean
  isForceStopped?: boolean
}

const initialOptInfo = {
  aid: null,
  fieldName: null,
  displayName: null,
  value: null,
  isOptimal: null,
  isReachedMaxTry: null,
  isForceStopped: null,
}

export default class Optimization {
  constructor(
    public rootStore: RootStore,
  ) {}
  // date for adjust target annuity to achieve target
  @observable public optInfo: any = initialOptInfo

  public setOptInfo (data: OptInfo) {
    this.optInfo = {
      ...this.optInfo,
      ...data,
    }
  }

  public resetOptInfo () {
    this.optInfo = initialOptInfo
  }
}

