import { v4 as uuidv4 } from 'uuid'
import Annuity, { AnnuityMArgs } from './Annuity'



export interface AnnuitySetMArgs<T = AnnuityMArgs> {
  id: string
  name: string
  holdings: T[]
}
interface AnnuitySetCArgs {
  holdings?: Annuity[]
  name: string
  desc?: string
}

export default class AnnuitySet {
  public readonly id: number | string
  public holdings: Annuity[]
  public name: string
  public desc: string
  constructor(
    {
      holdings = [],
      name,
      desc = '',
    }: AnnuitySetCArgs,
  ) {
    this.id = uuidv4()
    this.holdings = holdings
    this.name = name
    this.desc = desc
  }
  registerHoldings(holdings: Annuity[]) {
    this.holdings = this.holdings.concat(holdings)
  }
}


