import Annuity, { AnnuityCArgs } from './Annuity'
import AnnuitySet from './AnnuitySet'

class AnnuityBuilder {
  // build 一個只有一個 annuity 的 annuity set
  buildSingleAnnuitySet(
    annuityConfig: AnnuityCArgs, name: string,
  ): AnnuitySet {
    const annuitySet = new AnnuitySet({ name })
    const annuity = new Annuity({
      ...annuityConfig,
      name: annuityConfig.name ? annuityConfig.name : name,
    })

    annuitySet.registerHoldings([annuity])
    return annuitySet
  }
}

export default new AnnuityBuilder()

