// TODO
// 把 landing page 搬到這裡 deploy

import React, { useEffect } from 'react'
import { ThemeProvider } from 'styled-components' 

import Router from '@/router'
import { GlobalStyle } from '@/style/setup'

import { lightTheme } from '@/style/theme'
import { getCurrentUser } from '@/services/api/auth'
import { useStore } from '@/store/useStore'

const App: React.FC = () => {
  const { me } = useStore()
  useEffect(() => {
    getCurrentUser()
      .then((user: any) => {
        if (user) {
          me.onCurrentUser(user)
        }
      })
  // eslint-disable-next-line
  }, [])
  return (
    <ThemeProvider
      theme={lightTheme}
    >
      
      <GlobalStyle />
      <Router />
    </ThemeProvider>
  )
}

export default App
