import React from 'react'
import { arrayOf, object, string } from 'prop-types'
import styled from 'styled-components'
import * as d3 from 'd3'

import { WIDTH, HEIGHT, Margin } from './layout'
import { AnnuityFreq } from '@/services/core/Annuity'
import { formatCurrency, selectTickMod, formatStringDateYear, formatStringDateMonth } from '@/utils'

const POPUP_WIDTH = 120
const POPUP_HEIGHT = 80
const POPUP_PADDING_BOTTOM = 5

const Wrap = styled.div`
  position: relative;
  .${({ barClass }) => barClass } {
    transition: opacity .2s
  }

  &.hover-bar .${({ barClass }) => barClass} {
    opacity: 0.3
  }

  .popup {
    width: ${POPUP_WIDTH}px;
    height: ${POPUP_HEIGHT}px;
    background-color: #fff;
    box-shadow: 1px 1px 5px rgba(0,0,0,.24);
    position: absolute;
  }

  .domain {
    display: none;
  }

  .bar-active rect {
    opacity: 1 !important;
    cursor: pointer;
  }
`

export function hexToRgba(hex, alpha = 0.5) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})`
}

function mackColorRange(mainColor, count) {
  const d = 1 / count
  return new Array(count).fill(0).map((_, i) => {
    return hexToRgba(mainColor, 1 - i * d)
  })
}

class Chart {
  constructor(node, data, config) {
    this.layout = {
      margin: {
        ...Margin,
      },
      height: HEIGHT,
      width: WIDTH,
    }
    this.data = data
    this.config = config
    this.node = node
    this.svg = null
    this.data = data
    this.color = null
    this.series = null
    this.xScale = null
    this.xAxis = null
    this.xAxisG = null
    this.yScale = null
    this.yAxis = null
    this.yAxisG = null
    this.barsG = null
    this.bars = null
    this.legend = true
    this.isTransition = true
  }

  get keys() {
    return this.data.columns.slice(1)
  }

  get groupKey() {
    return this.data.columns[0]
  }

  get INTERVAL_WIDTH() {
    const { layout, data } = this
    const { width, margin } = layout
    const ret = (width - margin.left - margin.right) / data.length
    return ret
  }

  initSvg() {
    const {
      width,
      height,
    } = this.layout
    this.svg = d3.create('svg')
      .attr('viewBox', [0, 0, width, height])

    this.chartNode = this.makeSvgNode()
    
    this.node.append(this.chartNode)
    
  }

  makeSvgNode() {
    const { svg } = this
    return svg.node()
  }

  initD3Element () {
    const { data, layout, config } = this
    const { height, width, margin } = layout

    this.series = d3.stack()
      .keys(data.columns.slice(1))(data)
      // eslint-disable-next-line
      .map(d => (d.forEach(v => v.key = d.key), d))
    
    this.color = d3.scaleOrdinal()
      .domain(this.series.map(d => d.key))
      // .range(mackColorRange(config.chartColor, data.columns.length - 1))
      .range(config.colorRange)
      .unknown('#ccc')

    this.yScale = d3.scaleLinear()
      .domain([0, d3.max(this.series, d => d3.max(d, d => d[1]))])
      .rangeRound([height - margin.bottom, margin.top])

    this.xScale = d3.scaleBand()
      .domain(data.map(d => d.name))
      .range([margin.left, width - margin.right])
      .padding(0.3)

    this.legend = g => {
  
      const legendG =g
        .attr('class', 'legend')
        .attr('transform', `translate(${width}, 0)`)
        .attr('text-anchor', 'end')
        .attr('font-family', 'sans-serif')
        .attr('font-size', 10)
        .selectAll('g')
        .data(this.color.domain())
        .join('g')
        .attr('transform', (d, i) => `translate(0, ${2 + i *11})`)
      
      legendG.append('rect')
        .attr('x', -18)
        .attr('width', 18)
        .attr('height', 9)
        .attr('fill', d => this.color(d))
    
      legendG.append('text')
        .attr('x', -28)
        .attr('y', 4)
        .attr('dy', '0.35em')
        .attr('fill', '#555')
        .text(d => d)
    }
  }

  addMetaData(d, i) {
    return d
  }


  handleMouseover(d, chart, target) {
    const { node, layout, INTERVAL_WIDTH, yScale } = chart
    const { margin } = layout
    const meta = d.meta
    node.classList.add('hover-bar')
    target.parentNode.classList.add('bar-active')
    const $popup = document.createElement('div')
    const localMax = Math.max(...Object.values(meta.values))
    const top = yScale(localMax) - POPUP_HEIGHT - POPUP_PADDING_BOTTOM
    const left = margin.left + meta.idx * INTERVAL_WIDTH - POPUP_WIDTH / 2 + INTERVAL_WIDTH / 2
    $popup.innerHTML = `
      <div
        id="popup"
        class="popup"
        style="top: ${top}px; left: ${left}px">
      </div>
    `
    node.append($popup)
  }

  handleMouseleave(d, chart, target) {
    const { node } = chart
    node.classList.remove('hover-bar')
    const $popup = document.getElementById('popup')
    $popup && $popup.remove()
    target.parentNode.classList.remove('bar-active')
  }

  prepareBars () {
    const self = this
    const { svg, series, color, xScale, yScale, config, layout } = this
    const { height, margin } = layout

    // const formatValue = x => isNaN(x) ? "N/A" : x.toLocaleString("en")

    if (!this.parentG) {
      this.parentG = svg.append('g')
        .attr('class',  'group-parent')
    }

    this.barsG = this.parentG
      .selectAll('g')
      .data(series)
      .join('g')
      .attr('fill', d => color(d.key))
      // .attr('title', '123123')
    //     .text(d => `${d.data.name} ${d.key}
    // ${formatValue(d.data[d.key])}`);

    this.bars = this.barsG 
      .selectAll('rect')
      .data(self.addMetaData)
      .join(
        enter => {
          const rect = enter.append('rect')
            .attr('width', xScale.bandwidth())
            .attr('fill', d => color(d.key))
            .attr('class', (d, i) => config.barClass)

          rect 
            // for animation
            .attr('y', height - margin.bottom)
            .attr('height', 0)
            .transition(300)
            .delay(300)
            .duration(300)
            .attr('y', d => yScale(d[1]))
            .attr('height', d => yScale(d[0]) - yScale(d[1]))
          return rect
        }
        ,
        update => update,
        exit => exit.remove(),
      )

      .call(
        bar => bar.transition(1000)
          .attr('x', (d, i) => xScale(d.data.name))
          .attr('y', d => yScale(d[1]))
          .attr('height', d => yScale(d[0]) - yScale(d[1]))
          .attr('width', xScale.bandwidth())
          // .append('title')
          // .text(d => `${d.data.name} ${d.key}`)
        ,
      )
  }
  
  initAxis() {
    const { layout, xScale, yScale, data, config }  = this
    const { margin, width, height } = layout

    const { freq } = config

    const drawAreaWidth = width - margin.left - margin.right
    const tickMod = selectTickMod(data.length)
    const tickFormatter = freq === AnnuityFreq.YEAR
      ? (d, idx) => formatStringDateYear(d, idx, tickMod)
      : (d, idx) => formatStringDateMonth(d, idx, data.length, tickMod)

    this.yAxis = g => g
      .attr('transform', `translate(${margin.left + drawAreaWidth}, 0)`)
      .call(
        d3.axisLeft(yScale)
          .ticks(null, 's')
          .tickSize(drawAreaWidth),
      )
      .call(g => g.selectAll('.domain').remove())
      .call(g => g.selectAll('.tick:not(:first-of-type) line')
        .attr('stroke-opacity', 0.2)
        .attr('stroke-dasharray', '2,2'))
      .call(g => g.selectAll('.tick:first-of-type line')
        .attr('opacity', 0.3),
      )
      .call(g => g.selectAll('.tick text')
        .attr('color', '#999'),
      )
    this.xAxis = g => g
      .attr('transform', `translate(0, ${height-margin.bottom})`)
      .call(
        d3.axisBottom(xScale)
          .ticks()
          .tickFormat(tickFormatter),
      )
      .call(g => g.selectAll('.domain').remove())
      .call(g => g.selectAll('.tick text')
        .attr('color', '#999'),
      )
      .call(g => g.selectAll('.tick line')
        .attr('opacity', '0'),
      )
  }

  appendAxis() {
    const { svg, xAxis, yAxis } = this

    if (this.xAxisG) {
      this.xAxisG.transition()
        .duration(0)
        .ease(d3.easeSin)
        .call(this.xAxis)
    } else {
      this.xAxisG = svg.append('g').call(xAxis)
    }

    if (this.yAxisG) {
      this.yAxisG.transition()
        .duration(1000)
        .ease(d3.easeSin)
        .call(this.yAxis)
    } else {
      this.yAxisG = svg.append('g').call(yAxis)
    }
  }

  appendLegend() {
    const { svg, legend } = this
    if (this.legendG) {
      this.legendG.remove()
    }
    this.legendG = svg.append('g').call(legend)
  }


  initMouseover(e, self) {

  }

  update(data, freq) {
    this.data = data
    this.config.freq = freq
    this.initD3Element()
    this.initAxis()
    this.appendAxis()
    this.prepareBars()
    this.appendLegend()
  }

  render() {
    this.initSvg()
    this.initD3Element()
    this.initAxis()
    this.appendAxis()
    this.prepareBars()
    this.appendLegend()
  }
}

class StackedBarChart extends React.Component {
  static propTypes = {
    data: arrayOf(object),
    colorRange: arrayOf(string),
    chartColor: string,
    freq: string,
  }
  constructor() {
    super()
    this.state = {
      id: 'group-bar-' + Math.random().toString().slice(2, 10),
    }
  }
  componentDidMount() {
    this.draw(this.props.data)
  }

  componentDidUpdate() {
    this.draw(this.props.data)
  }

  draw(data) {
    const $node = document.getElementById(this.state.id)
    // if ($node.children.length) {
    //   $node.innerHTML = ''
    // }
    if (this.chart) {
      this.chart.update(data, this.props.freq)
    } else {
      this.chart = new Chart(
        $node,
        data,
        {
          colorRange: this.props.colorRange,
          chartColor: this.props.chartColor,
          freq: this.props.freq,
          barClass: this.state.barClass,
        },
      )
      this.chart.render()
    }

  }

  render() {
    return (
      <Wrap
        id={ this.state.id }
        style={{ width: '100%' }}
        barClass={ this.state.barClass }
      />
    )
  }
}

export default StackedBarChart
