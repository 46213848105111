import { observable, action } from 'mobx'
import set from 'lodash/set'
import { RootStore } from './RootStore'
import { getUserId, setUserId, removeUserId, getUserLocale, setUserLocale, removeUserLocale } from '@/utils'

const userId = getUserId()
const locale = getUserLocale()

export default class AuthState {
  constructor(
    public rootStore: RootStore,
  ) {}
  @observable public isAuth: boolean = !!userId
  @observable public userId: string | null = userId
  @observable public locale: string | null = locale
  
  @action
  update(path: string, payload: any) {
    set(this, path, payload)
  }

  @action
  auth(userId: string, locale: string) {
    if (!userId) return
    this.update('isAuth', true)
    this.userId = userId
    setUserId(userId)
    setUserLocale(locale)
  }

  @action
  unauth() {
    this.update('isAuth', false)
    this.userId = null
    removeUserId()
    removeUserLocale()
  }

  // if current user is null but isAuth is true than clear the login state
  checkCurrentUser(user: any) {
    if (!user && this.isAuth) {
      this.unauth()
      return
    }
    if (user && !this.isAuth) {
      this.auth(user.uid, locale)
    }
  }

}

