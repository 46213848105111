import get from 'lodash/get'
import annuityBuilder from './AnnuityBuilder'
import { AnnuityFreq } from './Annuity'
import { AnnuitySetMArgs } from './AnnuitySet'
import Time from './Time'
import Slots, { RegisterMode, RegisterConfig } from './Slots'
import { fromDateToN, fromNToDate } from '@/utils'
import AnnuityBuilder from './AnnuityBuilder'

interface Setting {
  freq: AnnuityFreq,
  discountRate: number,
  netAssetGrowthRateAnnual: number
  userNetAssets?: {
    [key: string]: number
  }
}

interface CashFlows<T = AnnuitySetMArgs> {
  annuitySets: T[]
}

export enum CashFlowType {
  INCOME = 'INCOME',
  EXPENDITURE = 'EXPENDITURE'
}

export enum StoryType {
  NORMAL = 'NORMAL',
  TEMPLATE = 'TEMPLATE'
}

export interface StoryMArgs<T = AnnuitySetMArgs> {
  id: string
  settings: Setting
  chapters: T[]
  name: string
  desc: string
  type: StoryType
}

const extendChapter = (chapters: any) => {
  // 1. merge holders
  const startDates = chapters.reduce((pre: any, {
    holdings,
  }: any) => {
    return [...pre, ...holdings.map(({ startAt }: any) => startAt.replace(/-/g, ''))]
  }, [])
  const endDates = chapters.reduce((pre: any, {
    holdings,
  }: any) => {
    return [...pre, ...holdings.map(({ startAt, n, freq }: any) => fromNToDate(startAt, n, freq).replace(/-/g, ''))]
  }, [])
  const mergedDates = [...startDates, ...endDates]
  // 2. keep max min
  const min = Math.min(...mergedDates).toString()
  const startAt = `${min.slice(0,4)}-${min.slice(4,6)}-${min.slice(6,8)}`
  const max = Math.max(...mergedDates).toString()
  const endAt = `${max.slice(0,4)}-${max.slice(4,6)}-${max.slice(6,8)}`
  // 3. based on max min create a 0 cashflow
  const n = fromDateToN(startAt, endAt, AnnuityFreq.YEAR)
  // create an Annuity for it
  return [...chapters, AnnuityBuilder.buildSingleAnnuitySet({
    amount: 0,
    freq: AnnuityFreq.YEAR,
    startAt: new Time(startAt),
    n,
    discountRate: 0,
  }, 'dummy-annuity-set')]
}

export default class Story {
  public id!: string
  public name: string
  public type: StoryType
  public desc: string
  public settings: Setting
  public chapters: AnnuitySetMArgs[]
  public income: CashFlows
  public expenditure: CashFlows
  constructor({
    id,
    settings,
    chapters,
    name,
    type,
    desc,
  }: StoryMArgs) {
    this.id = id
    this.name = name
    this.settings = settings
    this.chapters = chapters
    this.type = type
    this.desc = desc
    // this.goal = {}
    this.expenditure = {
      annuitySets: this.chapters
        .filter(
          ({ holdings }) => holdings.every(({ amount }) => amount <= 0,
          )),
    }
    this.income = {
      annuitySets: this.chapters
        .filter(
          ({ holdings }) => holdings.every(({ amount }) => amount >= 0,
          )),
    }
  }

  _createSlots(
    annuitySets: AnnuitySetMArgs[],
    slots?: Slots,
    config?: RegisterConfig,
  ) {
    const { freq, discountRate } = this.settings
    const _slots = slots || new Slots(get(config, 'freq', null) || freq)
    annuitySets.forEach(({
      holdings,
      name,
    }) => {
      holdings.forEach(({
        amount,
        n,
        startAt,
        freq,
      }) => {
        _slots.registerAnnuitySet(
          annuityBuilder.buildSingleAnnuitySet({
            discountRate,
            freq: freq || this.settings.freq,
            amount,
            n,
            startAt: typeof startAt === 'string' ? new Time(startAt) : startAt,
          }, name)
          , config,
        )
      })
    })
    return _slots
  }

  get netAssetCD() {
    const { freq } = this.settings
    // chapters 就是所有 data merge 在一起了
    const mergedSlots = this._createSlots(
      extendChapter(this.chapters), undefined, {
        freq,
      },
    )

    const netSlots = Slots.accumulateDataPoint(
      mergedSlots._mergeDataPoints(this.settings.netAssetGrowthRateAnnual, 'netAssets', freq),
      this.settings.netAssetGrowthRateAnnual,
    )

    const areaData = Object.keys(netSlots.dataPoints)
      .map((key) => {
        const dataPoint = netSlots.dataPoints[key][0]
        return {
          date: dataPoint.currentTime.moment,
          value: dataPoint.value,
        }
      })

    return {
      netSlots,
      areaData,
    }
  }
  get netFlowCD() {
    return ''
  }
  get InExCD() {
    const slots = this._createSlots(this.chapters, undefined, {
      freq: this.settings.freq,
    })
    const chartData = slots.toChartFormatMergeInEx()
    
    return {
      slots,
      chartData,
    } 
  }

  get incomeCD() {
    const incomeSlots = this._createSlots(this.income.annuitySets)
    return incomeSlots.toChartFormat()
  }

  get expendCD() {
    const expendSlots = this._createSlots(this.expenditure.annuitySets, undefined, {
      mode: RegisterMode.ABS,
      freq: this.settings.freq,
    })

    return expendSlots.toChartFormat()
  }

  get userNetAssets() {
    return this.settings.userNetAssets || {}
  }

  // 暫時用不到 Chapter 已取代掉 merged了
  _getMergedData() {
    const expendSlots = this._createSlots(this.expenditure.annuitySets, undefined, {
      freq: this.settings.freq,
    })
    const incomeSlots = this._createSlots(this.income.annuitySets, undefined, {
      freq: this.settings.freq,
    })

    return Slots.mergeSlots(expendSlots, incomeSlots)
  }
}