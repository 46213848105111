import React from 'react'
import styled from 'styled-components'
import {
  Card,
  Tooltip,
  Settings,
} from '@/components'
import { ct } from '@/style/theme'

interface WrapProps {
  isActive?: boolean
  isFixedWidth?: boolean
  isHasFooter?: boolean
}

const SettingsWrap = styled.div`
  position: absolute;
  top: 8px;
  right: 0;
  display: none;
`

const Wrap = styled(Card)<WrapProps>`
  .ant-card-body {
    padding: 12px 12px ${({ isHasFooter }) => isHasFooter ? '42px' : '12px'};
    position: relative;
    height: 100%;
  }
  margin: 2px 5px 2px;
  cursor: pointer;
  /* border-color: ${ct('mainColor')}; */
  border-color: #1890ff;
  box-shadow: 1px 1px 1px rgba(0,0,0,.24);
  ${({ isActive }: WrapProps) => isActive ? 'box-shadow:none' : 'border: 1px solid #eee;box-shadow:none'};
  box-sizing: border-box;
  
  /* display: inline-block; */
  ${({ isFixedWidth }) => isFixedWidth ? 'width: 250px;' : 'width: 100%;'}
  
  
  &:hover {
    ${({ isActive }: WrapProps) => isActive ? 'box-shadow:none' : 'box-shadow: 1px 1px 2px rgba(0,0,0,.14);'};
    ${SettingsWrap} {
      display: flex;
    }
  }
`

const Title = styled.h3`
  font-weight: bold;
`

const Desc = styled.p`
  color: ${ct('text2nd')};
  font-size: 13px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
`

const Footer = styled.div`
  position: absolute;
  bottom: 7px;
  font-size: 13px;
  color: #aaa;
`

interface Action {
  name: string,
  action: Function
}

interface Props extends UIComponentProps {
  onCardClick: () => void
  title: string
  desc: string
  footer?: any
  isFixedWidth?: boolean
  settingActions?: Action[]
  withTooltipDesc?: boolean
  isActive?: boolean
}

const StoryCard = ({
  onCardClick,
  title,
  desc,
  className,
  withTooltipDesc,
  settingActions,
  isActive,
  isFixedWidth = true,
  footer,
}: Props) => {

  const ActionBlock = 
  settingActions
    ? <SettingsWrap>
      <Settings settingActions={settingActions} />
    </SettingsWrap>
    : null

  if (withTooltipDesc) {
    return (
      <Tooltip
        title={ desc }
        mouseEnterDelay={0.7}
      >
        <Wrap
          className={className}
          onClick={ onCardClick }
          isActive={ isActive }
          isFixedWidth={ isFixedWidth }
          isHasFooter={ footer }
        >
          <Title style={{ margin: 0 }}>
            { title }
          </Title>
          { ActionBlock }
          <Footer>
            { footer ? footer : null }
          </Footer>
        </Wrap>
      </Tooltip>
    )
  }
  return (
    <Wrap
      className={className}
      onClick={ onCardClick }
      isActive={ isActive }
      isFixedWidth={ isFixedWidth }
      isHasFooter={ footer }
    >
      <Title>{ title }</Title>
      <Desc style={{ margin: 0 }}>{ desc }</Desc>
      { ActionBlock }
      <Footer>
        { footer ? footer : null }
      </Footer>
    </Wrap>
  )
}

export default StoryCard
