import { AnnuityFreq } from '@/services/core/Annuity'
import { v4 as uuidv4 } from 'uuid'
import Slots from './Slots'
import Time from './Time'
import { AnnuitySetMArgs } from './AnnuitySet'

interface ChartDataPoint {
  name: string,
  [column: string]: string | number
}

type C = ChartDataPoint[]
interface ChartData extends C {
  columns: Array<string | number>
}

class Formatter {
  mergeAnnuitySets(annuitySets: AnnuitySetMArgs[], name = 'Cash Flow'): AnnuitySetMArgs[] {
    return annuitySets.reduce((pre: AnnuitySetMArgs[], cur: AnnuitySetMArgs) => {
      pre[0].holdings = pre[0].holdings.concat(cur.holdings)
      return pre
    }, [{
      id: uuidv4(),
      name,
      holdings: [],
    }])
  }


  public slotsToChartData(slots: Slots): ChartData {
    const keys = Object.keys(slots.dataPoints).sort()

    // setup columns
    const columns: string[] = []
    for (const key of keys) {
      const elems = slots.dataPoints[key]
      elems.forEach(elem => {
        if (!columns.includes(elem.annuity.name)) {
          columns.push(elem.annuity.name)
        }
      })
    }

    // setup time series
    const first = new Time(keys[0]).getTs()
    const last = slots.slotType === AnnuityFreq.YEAR
      ? new Time(`${+keys[keys.length - 1] + 1}`).getTs()
      : new Time(keys[keys.length - 1]).getTs()
    
    let current = new Time(first)

    const tmp: any = []
    tmp.columns = ['name', ...columns]

    const timeSeries: ChartData = tmp

    while (
      current.getTs() < last
    ) {
      const timekey = current.getSlotKey({ slotType: slots.slotType }) as string
      timeSeries.push({
        name: timekey,
      })
      current = current.increment(1, slots.slotType)
    }

    // setup data in time series
    for (const data of timeSeries) {
      const currentSlot = slots.dataPoints[data.name]
      for (const col of columns) {
        if (currentSlot) {
          currentSlot.forEach(dataPoint => {
            if (dataPoint.annuity.name === col) {
              const value = dataPoint.value
              data[col] = data[col]
                ? +data[col] + value
                : value
            } else {
              data[col] = data[col] || 0
            }
          })
        } else {
          data[col] = 0
        }
      }
    }

    
    return timeSeries
  }

  toPercentage() {

  }
}

export default new Formatter()
