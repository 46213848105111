import set from 'lodash/set'
import { v4 as uuidv4 } from 'uuid'
import { observable, action, runInAction } from 'mobx'
import { fetchStory, updateStory } from '@/services/api/story/index'
import { StoryMArgs, StoryType } from '@/services/core/Story'
import { AnnuityFreq } from '@/services/core/Annuity'
import { slugify, storage, session } from '@/utils'
import StoryClass from '@/services/core/Story'
import { OfficialStory } from '@/constant'

import {
  EntityCRUD,
  RootStore,
  EntityStore,
  UpdatePayload,
  denormalizeAll,
  denormalizeOne,
  normalizeStory,
} from './RootStore'
import { notification, actionConfirm } from '@/components'

interface StoryEntities {
  [id: string]: StoryMArgs<string>
}

interface CreatePayload extends CreatePayloadWithoutId {
  id: string
}

interface StorySettings {
  discountRate?: number
  netAssetGrowthRateAnnual?: number
  freq?: AnnuityFreq
}

interface CreatePayloadWithoutId extends StorySettings {
  id?: string
  name: string
  desc?: string
  chapters?: string[]
  type?: StoryType
  settings?: Partial<StorySettings>
}

const createStory = ({
  id,
  name,
  desc = '',
  discountRate = 0.01,
  freq = AnnuityFreq.YEAR,
  netAssetGrowthRateAnnual = 0.05,
  type,
  settings,
  chapters = [],
}: CreatePayload) => (
  {
    id,
    desc,
    chapters,
    name,
    type,
    settings: {
      netAssetGrowthRateAnnual,
      discountRate,
      freq,
      ...settings,
    },
  }
)

type StoryListItem = any

const SET_DIRTY_BLACK_LIST: any = {
  'settings.freq': true,
}

export default class Story implements EntityStore, EntityCRUD {
  constructor(
    public rootStore: RootStore,
  ) {}
  @observable public isFetching: boolean = true
  @observable public listing: StoryListItem[] = []
  @observable public entities: StoryEntities = {}
  @observable public byOrder: string[] = []

  @action
  update(path: string, payload: UpdatePayload) {
    const { id, value } = payload
    set(this.entities[id], path, value)
    if (!SET_DIRTY_BLACK_LIST[path]) {
      this.rootStore.editState.setStoryDirty()
    }
  }

  @action
  create(payload: CreatePayloadWithoutId) {
    const id = payload.id || slugify(payload.name) + uuidv4().slice(0, 8)
    this.entities[id] = createStory({
      type: StoryType.NORMAL,
      id,
      ...payload,
    }) as any
    return id
  }

  @action
  remove(payload: UpdatePayload) {
    const { id } = payload
    Reflect.deleteProperty(this.entities, id)
  }

  from() {}
  to() {}
  merge() {}

  @action
  async fetchStory(sid: string) {
    this.isFetching = true
    try {
      const { data } = await fetchStory(sid)
      const {
        story,
        annuities,
        annuitySets,
      } = normalizeStory(data)
      // after await, modifying state again, needs an actions:
      runInAction(() => {
        this.create(story)
        annuities.forEach((annuity) => {
          this.rootStore.annuity.create(annuity)
        })
          
        const isDoneIntro = storage.get(storage.Keys.isDoneIntro)
        const shouldShowIntro = !isDoneIntro && OfficialStory[story.id as string]

        if (shouldShowIntro) {
          this.rootStore.storyTeller.init(annuitySets)
        } else {
          annuitySets.forEach((annuitySet: any) => {

            this.rootStore.annuitySet.create({
              id: annuitySet.id,
              name: annuitySet.name,
              desc: annuitySet.desc,
              holdings: annuitySet.annuities,
            })
          })
        }
      })
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error,
      })
    } finally {
      runInAction(() => {
        this.isFetching = false
        this.rootStore.editState.cleanStoryDirty()
      })
    }
  }

  @action
  setListing(list: StoryListItem[]) {
    this.listing = [...this.listing, ...list]
  }

  getStory(storyId: string) {
    const annuitySets = denormalizeAll(
      this.rootStore.annuitySet.entities,
      ['holdings'],
      this.rootStore.annuity.entities,
    )
    const story = denormalizeOne(
      storyId,
      this.entities,
      ['chapters'],
      annuitySets,
    )
    
    return new StoryClass(story)
  }
 
  async saveStory(story: any, history: any) {
    // No type handle later    
    const formatted = this._getSavedStoryFormat(story)
    if (story.type === StoryType.TEMPLATE) {
      this._handleSaveTemplateStory(formatted, history)
    } else {
      this._handleNormalSaveStory(formatted)
    }
  }

  _getSavedStoryFormat(story: any) {
    const { income, expenditure, ...filteredStory } = story
    return {
      ...filteredStory,
      chapters: filteredStory.chapters.map(({ holdings, ...ch }: any) => ({
        ...ch,
        annuities: holdings,
      })),
    }
  }
  
  async _handleSaveTemplateStory(story: any, history: any) {
    actionConfirm({
      onOk: () => {
        this.rootStore.editState.cleanStoryDirty()
        setTimeout(() => {
          session.set(session.Keys.unsavedStory, story)
          history.push('/signup')
        })
      },
      title: '登入後幫您儲存！',
      content: `將會轉導至註冊頁面，故事 "${story.name}" 將會在登入成功後儲存（非Google登入則需通過Email驗證）`,
    })
  }

  async _handleNormalSaveStory(story: any) {
    await updateStory(story)
    this.rootStore.editState.cleanStoryDirty()
    notification.success({
      message: '已儲存',
    })
  }

  addChapter(storeId: string, asId: string) {
    this.update('chapters', {
      id: storeId,
      value: [...this.entities[storeId].chapters, asId],
    })
    this.rootStore.editState.setStoryDirty()
  }

  removeChapter(storeId: string, asId: string) {
    this.update('chapters', {
      id: storeId,
      value: this.entities[storeId].chapters.filter(id => id !== asId),
    })
    this.rootStore.editState.setStoryDirty()
  }

  debug() {
    return JSON.stringify(this.entities, null, 2)
  }
}
