import Loadable from 'react-loadable'
import { PageLoading } from '@/components'

const LoginLoadable = Loadable({
  loader: () => import('.'),
  loading: PageLoading,
})


export default LoginLoadable
