import { StoryMArgs } from './../services/core/Story'
import { v4 as uuidv4 } from 'uuid'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import set from 'lodash/set'
import Me from './Me'
import Story from './Story'
import Annuity from './Annuity'
import AnnuitySet from './AnnuitySet'
import AppState from './AppState'
import EditState from './EditState'
import AuthState from './AuthState'
import Optimization from './Optimization'
import StoryTeller from './StoryTeller'

export interface EntityStore {
  entities: { [key: string]: any }
  byOrder: string[]
}

export interface UpdatePayload {
  id: string,
  [key: string]: any
}
export interface CreatePayload {
  [key: string]: any
}

export interface EntityCRUD<T = any> {
  // from API or other data source to the store format
  from(data: { [key: string]: T }, replace: boolean): void

  // from store format API 
  to(): any

  // merge input data to the current store
  merge(data: { [key: string]: T }, replace: boolean): void
  update(path: string, payload: UpdatePayload): void
  create(payload: CreatePayload): string
  remove(payload: UpdatePayload): void
}

export function denormalizeOne(
  id: string,
  parentEntities: any,
  paths: string[],
  childEntities: any,
) {
  const parentEntity = parentEntities[id]
  const parentClone = cloneDeep(parentEntity)
  paths.forEach(path => {
    const ids: string[] = get(parentEntity, path, [])
    const populated = ids.map(id => childEntities[id]).filter(Boolean)
    set(parentClone, path, populated)
  })
  return parentClone
}

export function denormalizeAll(
  parentEntities: any,
  paths: any,
  childEntities: any,
) {
  const keys = Object.keys(parentEntities)
  return keys.reduce((pre: any, cur) => {
    pre[cur] = denormalizeOne(cur, parentEntities, paths, childEntities)
    return pre
  }, {})
}

export function normalizeStory (rawStory: StoryMArgs) {
  const clonedStory: any = cloneDeep(rawStory)
  const annuities: any[] = []
  const asids: string[] = []
  clonedStory.chapters.forEach((as: any) => {
    const aids: string[] = []
    as.annuities.forEach((annuity: any) => {
      const aid = annuity.id || uuidv4()
      aids.push(aid)
      annuities.push({
        ...annuity,
        id: aid,
      })
    })
    as.annuities = aids
    as.id = as.id || uuidv4()
    asids.push(as.id)
  })
  const annuitySets = clonedStory.chapters
  clonedStory.chapters = asids

  return {
    story: clonedStory,
    annuitySets,
    annuities,
  }
}

export interface Store {
  annuity: Annuity
  annuitySet: AnnuitySet
  story: Story
  me: Me
  appState: AppState
  editState: EditState
  authState: AuthState
  optimization: Optimization
  storyTeller: StoryTeller
}

export class RootStore implements Store {
  public annuity: Annuity
  public annuitySet: AnnuitySet
  public story: Story
  public me: Me
  public appState: AppState
  public editState: EditState
  public authState: AuthState
  public optimization: Optimization
  public storyTeller: StoryTeller
  constructor() {
    this.annuity = new Annuity(this)
    this.annuitySet = new AnnuitySet(this)
    this.story = new Story(this)
    this.me = new Me(this)
    this.appState = new AppState(this)
    this.editState = new EditState(this)
    this.authState = new AuthState(this)
    this.optimization = new Optimization(this)
    this.storyTeller = new StoryTeller(this)
  }
}
