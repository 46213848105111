import { observable } from 'mobx'
import { RootStore } from './RootStore'

export default class AppState {
  constructor(
    public rootStore: RootStore,
  ) {}
  @observable public currentStoryId: string = '123'
}

