import Story from './../services/core/Story'
import find from 'lodash/find'
import { MAX_NUM, MIN_NUM } from '@/constant'

const MAXIMUM_OPT_TRY_COUNT = 100
const MAX_N = 960
const INPUT_MIN = 1000

function selectAnnuity(
  story: Story,
  annuityId: string,
) {
  const annuitySets = story.chapters
  const annuities = annuitySets.reduce((pre: any[], cur) => {
    return [
      ...pre,
      ...cur.holdings,
    ]
  }, [])
  const targetAnnuity = find(annuities, ({ id }) => id === annuityId)
  return targetAnnuity
}

function getDelta(netAccessCD: any, index: any, targetEndAmount: number) {
  const targetDataPoint = index
    ? netAccessCD[index]
    : netAccessCD[netAccessCD.length - 1]
  return targetDataPoint.value - targetEndAmount
}

function getNextGuess(leftOrRight: number, guess: number) {
  return (leftOrRight + +guess) / 2
}

// binary search the optimal solution
async function findOptimal(
  story: Story,
  annuityId: string,
  targetEndAmount: number,
  fieldName: string,
  min: number,
  max: number,
  targetIdx:number,
  originalValue:number,
) {
  window.shouldOptimalStop = false
  let left = min
  let right = max
  let cachedCurrentNet = story.netAssetCD.areaData
  
  const selectedAnnuity = selectAnnuity(story, annuityId)
  // console.log('selectedAnnuity', selectedAnnuity)
  let delta = getDelta(cachedCurrentNet, targetIdx, targetEndAmount)

  let i = 0
  let guess: number = selectedAnnuity[fieldName]

  async function recurOptimal(delta: any, guess: any, i: any): Promise<any> {
    if (Math.abs(delta) <= 1 || i >= MAXIMUM_OPT_TRY_COUNT || window.shouldOptimalStop) {
      const realMin = originalValue > 0 ? INPUT_MIN : min
      const realMax = originalValue > 0 ? max : -INPUT_MIN
      const isInRange = originalValue > 0
        ? guess >= realMin && guess <= realMax
        : guess >= realMin && guess <= realMax
      return {
        guess,
        isOptimal: i < MAXIMUM_OPT_TRY_COUNT,
        isForceStopped: window.shouldOptimalStop,
        isBetterThanOrigin: guess < originalValue,
        isInRange,
        // 已經不用賺錢了(income is min)，還可以達到目標 or
        // 己經消費超爆多(expend is min(負很多))，還可以達到目標
        isGoodOutOfRange: !isInRange && guess < realMin,
        realMin,
        realMax,
      }
    }
    // const selectedAnnuity = selectAnnuity(story, annuityId)
    guess = selectedAnnuity[fieldName]
    // debug
    // console.log('i: ', i)
    // console.log('guess: ', guess)
    // console.log('delta: ', delta)
    // console.log('---------')
    if (delta > 0) {
      right = guess
      guess = getNextGuess(left, guess)
    } else {
      left = guess
      guess = getNextGuess(right, guess)
    }

    selectedAnnuity[fieldName] = guess
    delta = getDelta(story.netAssetCD.areaData, targetIdx, targetEndAmount)
    i += 1
    return await new Promise((res, rej) => {
      window.requestAnimationFrame(() => res(recurOptimal(delta, guess, i)))
      // setTimeout(() => res(recurOptimal(delta, guess, i)), 100)
    })
  }

  const ret = await recurOptimal(delta, guess, i)
  return ret
}


export async function findAmountOptimal (
  story: Story,
  annuityId: string,
  targetEndAmount: number,
  targetIdx: number,
  originalValue: number,
) {
  const ret = await findOptimal(
    story,
    annuityId,
    targetEndAmount,
    'amount',
    MIN_NUM,
    MAX_NUM,
    targetIdx,
    originalValue,
  )

  return ret
}


export async function findNOptimal (
  story: Story,
  annuityId: string,
  targetEndAmount: number,
  targetIdx: number,
  originalValue: number,
) {
  const ret = await findOptimal(
    story,
    annuityId,
    targetEndAmount,
    'n',
    0,
    MAX_N,
    targetIdx,
    originalValue,
  )

  return ret
}
