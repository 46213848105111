
import styled from 'styled-components'

const Mask = styled.div<{ bgc?: string }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  ${({ bgc }) => bgc ? `background-color: ${bgc};` : ''}
`

export default Mask
