import React from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import get from 'lodash/get'
import styled from 'styled-components'
import {
  Avatar,
  Dropdown,
  Menu,
} from '@/components'
import { updateMergeUser } from '@/services/api/user'
import { getUserId, setUserLocale } from '@/utils'
import { normalizeLocale } from '@/containers/LanguageProvider'

const Wrap = styled.div``

const Span = styled.div`
  display: block;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.4);
  transition: all 0.3s;
  font-size: 12px;
`

interface Props {
  handleLogout: () => void
  userInfo: any
}

const ProfileSection = ({
  userInfo,
  handleLogout,
}: Props) => {
  const { formatMessage } = useIntl()
  const email = get(userInfo, 'email', null)

  if (!email) return null

  const updateLocal = async (locale: string) => {
    await updateMergeUser({
      id: getUserId(),
      locale,
    })
    setUserLocale(locale)
    window.location.reload()
  }
  return (
    <Wrap>
      <Dropdown
        overlay={
          <Menu>
            <Span>
              {email}
            </Span>
            <Menu.Divider />
            {
              userInfo.providerData.some(({ providerId }: any) => providerId === 'password')
                ? <Menu.Item>
                  <Link to="/reset-password">
                    { formatMessage({ id: 'reset-password' }) }
                  </Link>
                </Menu.Item>
                : null
            }
            <Menu.Divider />
            <Menu.Item>
              <Link to="/about">
                { formatMessage({ id: 'nav.about-visify' }) }
              </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
              onClick={ () => {
                updateLocal('en')
              }}
            >
              English
            </Menu.Item>
            <Menu.Item
              onClick={ () => {
                updateLocal('zh')
              }}
            >
              中文
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={ handleLogout }>
              { formatMessage({ id: 'logout' }) }
            </Menu.Item>
          </Menu>
        }
        placement="bottomRight"
        trigger={['click']}
      >
        <Avatar
          style={{ cursor: 'pointer' }}
          src={ userInfo.photoURL }
          size="small"
        >
          {email[0].toUpperCase()}
        </Avatar>
      </Dropdown>
      
    </Wrap>
  )
}


export default ProfileSection
