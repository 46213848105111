import { observable, action } from 'mobx'
import { RootStore } from './RootStore'

type FirebaseUserInfo = any

interface Profile {
  retireAt: number
  birthday: number
  lifeExpectancy: number
}

export default class Me {
  constructor(
    public rootStore: RootStore,
  ) {}
  @observable public userInfo: FirebaseUserInfo = null
  @observable public profile: Profile = {
    retireAt: 100,
    birthday: 100,
    lifeExpectancy: 100,
  }
  @observable public storyIds: string[] = []

  @action
  onCurrentUser(userInfo: FirebaseUserInfo) {
    this.userInfo = userInfo
  }
}

