import { db } from '../FirebaseCore'

class FirestoreUsers {
  public users: any
  constructor() {
    this.users = db.collection('users')
  }
  public async getOne(userId: string) {
    return this.users
      .where('id', '==', userId)
      .get()
  }
  public async update(user: SavedUser) {
    if (!user.id) {
      throw new Error('user should have a id')
    }
    return this.users.doc(user.id).set(user, { merge: true })
  }
  public async updateMerge(user: SavedUser) {
    if (!user.id) {
      throw new Error('user should have a id')
    }
    return this.users.doc(user.id).update(user)
  }
}
 
export default new FirestoreUsers()