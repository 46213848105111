// TODO: Notification i18n
// TODO: email verify template 
// TODO: forget password verify template
import notification from '@/components/feedback/notification'
import FirebaseAuth from '@/firebase/FirebaseAuth'

const CodeMessageMap: any = {
  'auth/invalid-email': 'auth/invalid-email',
  'auth/user-not-found': 'auth/user-not-found',
  'auth/wrong-password': 'auth/wrong-password',
  'auth/weak-password': 'auth/weak-password',
  'auth/email-already-in-use': 'auth/email-already-in-use',
}

export const emailSignup = async (
  email: string,
  password: string,
  { formatMessage }: APIConfig,
) => {
  try {
    await FirebaseAuth.doCreateUserWithEmailAndPassword(email, password)
    notification.success({
      message: formatMessage({ id: 'api.signup.success.title' }),
    })
  } catch (e) {
    const msgId = CodeMessageMap[e.code] ? CodeMessageMap[e.code] : e.message
    notification.error({
      message: formatMessage({ id: 'api.signup.fail.title' }),
      description: formatMessage({ id: msgId }),
    })
    throw new Error(e.message)
  }
}


export const emailLogin = async (
  email: string,
  password: string,
  { formatMessage }: APIConfig,
) => {
  try {
    const data = await FirebaseAuth.doSignInWithEmailAndPassword(email, password)
    // notification.success({
    //   message: 'Login Succeed',
    //   description: ''
    // })
    return data
  } catch (e) {
    const msgId = CodeMessageMap[e.code] ? CodeMessageMap[e.code] : e.message
    notification.error({
      message: formatMessage({ id: 'api.login.fail.title' }),
      description: formatMessage({ id: msgId }),
    })
    throw new Error(e.message)
  }
}


export const logout = async ({ formatMessage }: APIConfig) => {
  try {
    await FirebaseAuth.doSignOut()
    notification.success({
      message: formatMessage({ id: 'api.logout.success.title' }),
    })
  } catch (e) {
    const msgId = CodeMessageMap[e.code] ? CodeMessageMap[e.code] : e.message
    notification.error({
      message: formatMessage({ id: 'api.logout.fail.title' }),
      description: formatMessage({ id: msgId }),
    })
    throw new Error(e.message)
  }
}

export const resetPassword = async (
  password: string,
  { formatMessage }: APIConfig,
) => {
  
  try {
    await FirebaseAuth.doPasswordUpdate(password)
    notification.success({
      message: formatMessage({ id: 'api.reset-password.success.title' }),
    })
  } catch (e) {
    const msgId = CodeMessageMap[e.code] ? CodeMessageMap[e.code] : e.message
    notification.error({
      message: formatMessage({ id: 'api.reset-password.fail.title' }),
      description: formatMessage({ id: msgId }),
    })
    throw new Error(e.message)
  }
}


export const resetPasswordMail = async (
  email: string,
  { formatMessage }: APIConfig,
) => {
  try {
    await FirebaseAuth.doPasswordReset(email)
    notification.success({
      message: formatMessage({ id: 'api.reset-password-mail.success.title' }),
    })
  } catch (e) {
    const msgId = CodeMessageMap[e.code] ? CodeMessageMap[e.code] : e.message
    notification.error({
      message: formatMessage({ id: 'api.reset-password-mail.fail.title' }),
      description: formatMessage({ id: msgId }),
    })
    throw new Error(e.message)
  }
}

export const googleLogin = async () => new Promise((resolve, reject) => {
  FirebaseAuth.doGoogleLogin()
})

export const getCurrentUser = async () => new Promise((resolve, reject) => {
  try {
    FirebaseAuth.auth.onAuthStateChanged((user: any) => {
      resolve(user)
    })
  } catch (e) {
    reject(e)
  }
})
