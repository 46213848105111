// only key in this obj is allowed to storage
const Keys = {
  isDoneIntro: '__idi',
  userId: '__u',
  userLocale: '__l',
  unsavedStory: '__s',
}

export const storage = {
  Keys,
  get(key: string) {
    const strVal = localStorage.getItem(key)
    try {
      return strVal && JSON.parse(strVal)
    } catch (e) {
      return strVal
    }
  },
  set(key: string, value: any) {
    const strVal = JSON.stringify(value)
    localStorage.setItem(key, strVal)
  },
  remove(key: string) {
    localStorage.removeItem(key)
  },
}

export const session = {
  Keys,
  get(key: string): any {
    const strVal = sessionStorage.getItem(key)
    try {
      return strVal && JSON.parse(strVal)
    } catch (e) {
      return strVal
    }
  },
  set(key: string, value: any) {
    const strVal = JSON.stringify(value)
    sessionStorage.setItem(key, strVal)
  },
  remove(key: string) {
    sessionStorage.removeItem(key)
  },
}

export const getUserId = () => {
  return storage.get(Keys.userId)
}

export const getUserLocale = () => {
  return storage.get(Keys.userLocale) || window.navigator.language
}

export const setUserId = (userId: string) => {
  return storage.set(Keys.userId, userId)
}

export const setUserLocale = (locale: string) => {
  return storage.set(Keys.userLocale, locale)
}

export const removeUserId = () => {
  return storage.remove(Keys.userId)
}

export const removeUserLocale = () => {
  return storage.remove(Keys.userLocale)
}

