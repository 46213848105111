import { StoryType } from '@/services/core/Story'
import { getUserId } from '@/utils'
import { db } from '../FirebaseCore'

class FirestoreStories {
  public stories: any
  public storyTemplates: any
  constructor() {
    this.stories = db.collection('stories')
    this.storyTemplates = db.collection('stories')
  }
  public async getAll() {
    const userId = getUserId()
    return this.stories
      .where('owner', '==', userId)
      .get()
  }
  public async getAllTemplate() {
    return this.stories
      .where('type', '==', StoryType.TEMPLATE)
      .get()
  }
  public async getOne(sid: string) {
    const userId = getUserId()
    if (userId) {
      return this.stories
        .where('owner', '==', userId)
        .where('id', '==', sid)
        .get()
    } else {
      return this.stories
        .where('type', '==', StoryType.TEMPLATE)
        .where('id', '==', sid)
        .get()
    }
  }
  public async getOneIntro(sid: string) {
    const userId = getUserId()
    if (userId) {
      return this.stories
        .where('owner', '==', userId)
        .where('id', '==', sid)
        .get()
    } else {
      return this.stories
        .where('type', '==', StoryType.TEMPLATE)
        .where('id', '==', sid)
        .get()
    }
  }
  public async create(story: any) {
    if (!story.id) {
      throw new Error('story should have a id')
    }
    return this.stories.doc(story.id).set(story)
  }
  public async update(story: any) {
    if (!story.id) {
      throw new Error('story should have a id')
    }
    return this.stories.doc(story.id).set(story)
  }
  public async delete(story: any) {
    if (!story.id) {
      throw new Error('story should have a id')
    }
    return this.stories.doc(story.id).delete()
  }
}
 
export default new FirestoreStories()