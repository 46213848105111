import { Modal } from '@/components'
import { dangerouslyFormatMessage } from '@/containers/LanguageProvider'

interface  ActionConfirmArgs {
  onOk: (...args: any[]) => any,
  title?: string,
  content?: string,
  okText?: string,
  cancelText?: string
}

function actionConfirm({
  onOk, 
  title = dangerouslyFormatMessage('confirm'),
  content = '',
  okText = dangerouslyFormatMessage('confirm'),
  cancelText = dangerouslyFormatMessage('cancel'),
}: ActionConfirmArgs) {
  Modal.confirm({
    title,
    content,
    onOk,
    okText,
    cancelText,
  })
}

export default actionConfirm
