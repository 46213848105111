import { createGlobalStyle } from 'styled-components'
import { Sizes, ct } from './theme'


export const GlobalStyle =createGlobalStyle`
  @media print {}
  .ant-btn-loading-icon {
    margin-right: 8px !important;
  }
  body {
    color: ${ct('textMain')};
    background-color: ${ct('body')};
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Ubuntu', sans-serif;
    /* font-family: 'Bonbon', sans-serif; */
    /* font-family: 'Literata', sans-serif; */
    /* font-family: 'Quicksand', sans-serif; */
    /* font-family: 'Indie Flower', sans-serif; */
    /* font-family: 'Rokkitt', sans-serif; */
    /* font-family: 'Kalam', sans-serif; */
    /* font-family: 'Josefin Slab', sans-serif; */
    /* Gotham SSm A,Gotham SSm B,PingFang TC,Microsoft JhengHei,PMingLiU,sans-serif */
  }
  h1, h2, h3, h4, h5, h6, p {
    color: ${ct('textMain')};
  }
  a {
    /* color: ${ct('main')}; */
  }
  .hl { /* highlight */
    /* font-weight: bold; */
    /* color: #222; */
  }
  .il-link {
    color: ${ct('textMain')};
  }

  .hide-s {
    @media screen and (max-width: ${Sizes.screen.s}px) {
      display: none;
    }
  }
@keyframes cell-appear {
  0% {
    background-color: rgba(200,200,200,1);
  }
  100% {
      background-color: rgba(200,200,200,0);
  }
}
@keyframes first-indicator {
  0% {
    transform: translateX(0vw) scaleX(0);
  }
  25% {
      transform: translateX(0vw) scaleX(0.5);
  }
  50% {
      transform: translateX(25vw) scaleX(0.75);
  }
  75% {
      transform: translateX(100vw) scaleX(0);
  }
  100% {
      transform: translateX(100vw) scaleX(0);
  }
}
@keyframes second-indicator {
  0% {
    transform: translateX(0vw) scaleX(0);
  }
  60% {
      transform: translateX(0vw) scaleX(0);
  }
  80% {
      transform: translateX(0vw) scaleX(0.6);
  }
  100% {
      transform: translateX(100vw) scaleX(0.1);
  }
}

.item-enter {
  opacity: 0;
  transform: translateX(-50px);
}
.item-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: transform 150ms ease-in, opacity 300ms ease-in;
}
.item-exit {
  opacity: 1;
  transform: translateX(0);
}
.item-exit-active {
  opacity: 0;
  transform: translateX(-50px);
  transition: transform 150ms ease-in, opacity 150ms ease-in;
}


`
