// TODO
// 把支出組成，收入組成 的 tooltip 搞回來 (不好加)
// 處理 淨資產和收支比較 tooltip 爆版 -> (從 formatter 下手)
import React from 'react'
import styled from 'styled-components'
import clamp from 'lodash/clamp'
import { arrayOf, object, string, func } from 'prop-types'
import * as d3 from 'd3'

import { AnnuityFreq } from '@/services/core/Annuity'
import { formatCurrency, selectTickMod, formatStringDateYear, formatStringDateMonth } from '@/utils'
import { WIDTH, HEIGHT, Margin } from './layout'

const POPUP_WIDTH = 170
const LINE_HEIGHT = 20
const TITLE_HEIGHT = 30
const POPUP_PADDING_BOTTOM = 5

const Wrap = styled.div`
  position: relative;
  .${({ barClass }) => barClass } {
    transition: opacity .2s
  }

  &.hover-bar .${({ barClass }) => barClass} {
    opacity: 0.3
  }

  .popup {
    width: ${POPUP_WIDTH}px;
    background-color: #fff;
    border: 1px solid #eee;
    position: absolute;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 4px;
    h5 {
      font-size: 15px;
      font-weight: bold;
      margin: 0;
    }
    p {
      font-size: 13px;
      margin: 4px 0;
      line-height: 1;
      span {
        color: #888;
      }
    }
  }

  .domain {
    display: none;
  }

  .bar-active rect{
    opacity: 1 !important;
    cursor: pointer;
  }
`
export function hexToRgba(hex, alpha = 0.5) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})`
}

class Chart {
  constructor(node, data, config) {
    this.layout = {
      margin: Margin,
      height: HEIGHT,
      width: WIDTH,
    }
    this.color = d3.scaleOrdinal()
      // .range(config.colorRange)
      // .range(['#2EB67D', '#E01E5A'])
      // .range([hexToRgba('#2EB67D', .7), hexToRgba('#E01E5A')])
      .range([hexToRgba('#2EB67D'), '#92c0df'])
    this.config = config
    this.node = node
    this.svg = null
    this.data = data
    this.x0Scale = null
    this.x1Scale = null
    this.xAxis = null
    this.xAxisG = null
    this.yScale = null
    this.yAxis = null
    this.yAxisG = null
    this.barsG = null
    this.bars = null
    this.legend = true
  }

  get keys() {
    return this.data.columns.slice(1)
  }

  get groupKey() {
    return this.data.columns[0]
  }

  get INTERVAL_WIDTH() {
    const { layout, data } = this
    const { width, margin } = layout
    const ret = (width - margin.left - margin.right) / data.length
    return ret
  }

  get GAP_WIDTH() {
    const { data } = this
    return 100 / data.length
  }

  initSvg() {
    const {
      width,
      height,
    } = this.layout
    this.svg = d3.create('svg')
      .attr('viewBox', [0, 0, width, height])

    this.chartNode = this.makeSvgNode()
    
    this.node.append(this.chartNode)
  }

  makeSvgNode() {
    const { svg } = this
    return svg.node()
  }

  initD3Element () {
    const { layout, data, keys, groupKey, color, config } = this
    const { margin, height, width } = layout

    this.x0Scale = d3.scaleBand()
      .domain(data.map(d => d[groupKey]))
      .range([margin.left, width-margin.right])
      .paddingInner(0.1)

    const maxValue = d3.max(data, d => d3.max(keys, key => d[key]))

    this.yScale = d3.scaleLinear()  
      .domain([0, maxValue]).nice()
      .range([height-margin.bottom, margin.top])

    this.x1Scale = d3.scaleBand()
      .domain(keys)
      .range([0, this.x0Scale.bandwidth()])
      .padding(0.2)

    this.legend = g => {
  
      const legendG = g
        .attr('class', 'legend')
        .attr('transform', `translate(${width}, 0)`)
        .attr('text-anchor', 'end')
        .attr('font-family', 'sans-serif')
        .attr('font-size', 10)
        .selectAll('g')
        .data(color.domain())
        .join('g')
        .attr('transform', (d, i) => `translate(0, ${2 + i *11})`)
      
      legendG.append('rect')
        .attr('x', -18)
        .attr('width', 18)
        .attr('height', 9)
        .attr('fill', d => color(d))
    
      legendG.append('text')
        .attr('x', -28)
        .attr('y', 4)
        .attr('dy', '0.35em')
        .attr('fill', '#555')
        .text(d => config.formatMessage({ id: d }))
    }
  }

  addMetaData(d, i) {
    const { keys } = this
    return [...keys, 'comb'].map(key => {
      const meta = {
        values: keys.reduce(
          (pre, cur) => {
            pre[cur] = d[cur]
            return pre
          }, {},
        ),
        name: d.name,
        idx: i,
      }
  
      if (key === 'comb') {
        const localMax = Math.max(...Object.values(meta.values))
        return {
          key,
          value: localMax,
          meta,
        }
      } else {
        return {
          key,
          value: d[key],
        }
      }
    })
  }

  handleMouseover(d, chart, target) {
    const { node, layout, INTERVAL_WIDTH, yScale, color, config, GAP_WIDTH } = chart
    const { margin } = layout
    const meta = d.meta

    node.classList.add('hover-bar')
    target.parentNode.classList.add('bar-active')
    const $popup = document.createElement('div')
    const valueKeys = Object.keys(meta.values)
    const localMax = Math.max(...Object.values(meta.values))
    const top = yScale(localMax) - POPUP_PADDING_BOTTOM - (valueKeys.length * LINE_HEIGHT) - TITLE_HEIGHT
    const left = clamp(
      margin.left + meta.idx * (INTERVAL_WIDTH + GAP_WIDTH) - POPUP_WIDTH / 2 + INTERVAL_WIDTH / 2,
      margin.left + 2,
      layout.width,
    )
    
    $popup.innerHTML = `
      <div
        id="popup"
        class="popup"
        style="top: ${top}px; left: ${left}px"
      >
        <h5>${meta.name}</h5>
        ${
  valueKeys.reduce((pre, cur) => {
    pre += `<p style="color: ${color(cur)}">
      ${config.formatMessage({ id: cur })}: <span>
        ${formatCurrency('en', meta.values[cur])}
      </span>
    </p>`
    return pre
  }, '')
}
      </div>
    `
    node.append($popup)
  }

  handleMouseleave(d, chart, target) {
    const { node } = chart
    node.classList.remove('hover-bar')
    const $popup = document.getElementById('popup')
    $popup && $popup.remove()
    target.parentNode.classList.remove('bar-active')
  }

  prepareBars () {
    const self = this
    const {
      INTERVAL_WIDTH,
      svg,
      data,
      groupKey,
      x0Scale,
      x1Scale,
      keys,
      yScale,
      layout,
      config,
      color,
      node,
    } = this
    const { margin, height } = layout

    const VALUE_ZERO_HEIGHT = 2

    if (!this.parentG) {
      this.parentG = svg.append('g')
        .attr('class',  'group-parent')
    }

    this.barsG = this.parentG
      .selectAll('g')
      .data(data)
      .join(
        enter => enter.append('g')
          .attr('transform', d => `translate(${x0Scale(d[groupKey])}, 0)`),
        update => update,
        exit => exit.remove(),
      )
      .call(barG => barG.attr('transform', d => `translate(${x0Scale(d[groupKey])}, 0)`))
        

    this.bars = this.barsG
      .selectAll('rect')
      .data(this.addMetaData.bind(this))
      .join(
        enter => {
          const rect = enter.append('rect')
            .attr('x', d => d.key === 'comb' ? 0 : x1Scale(d.key))
            .attr('width', d => d.key === 'comb' ? INTERVAL_WIDTH + 2 : x1Scale.bandwidth())
            .attr('fill', d => d.key === 'comb' ? 'rgba(0,0,0,.0)' : color(d.key))
            .attr('class', (d, i) => config.barClass)
            .on('mouseover', function(d) { self.handleMouseover(d, self, this) })
            .on('mouseleave', function(d) { self.handleMouseleave(d, self, this) })

          rect 
          // for animation
            .attr('y', height - margin.bottom)
            .attr('height', 0)
            .transition(300)
            .delay(300)
            .duration(300)
            // 在 value 為 0 的狀況下，秀小小的一截
            .attr('y', d => {
              return Math.min(height - margin.bottom - VALUE_ZERO_HEIGHT, yScale(d.value))
            })
            .attr('height', d => Math.max(VALUE_ZERO_HEIGHT, yScale(0) - yScale(d.value)) )
          return rect
        }
        ,
        update => update,
        exit => exit.remove(),
      )
      .call(
        bar => bar.transition(1000)
          .attr('x', d => d.key === 'comb' ? 0 : x1Scale(d.key))
          .attr('width', d => d.key === 'comb' ? INTERVAL_WIDTH + 2 : x1Scale.bandwidth())
          .attr('fill', d => d.key === 'comb' ? 'rgba(0,0,0,.0)' : color(d.key))
          .attr('class', (d, i) => config.barClass)
          .attr('y', d => {
            return Math.min(height - margin.bottom - VALUE_ZERO_HEIGHT, yScale(d.value))
          })
          .attr('height', d => Math.max(VALUE_ZERO_HEIGHT, yScale(0) - yScale(d.value)) ),
      )

  }
  
  initAxis() {
    const { layout, x0Scale, yScale, config, data }  = this
    const { margin, width, height } = layout
    const { freq } = config

    const drawAreaWidth = width - margin.left - margin.right
    const tickMod = selectTickMod(data.length)
    const tickFormatter = freq === AnnuityFreq.YEAR
      ? (d, idx) => formatStringDateYear(d, idx, tickMod)
      : (d, idx) => formatStringDateMonth(d, idx, data.length, tickMod)

    this.yAxis = g => g
      .attr('transform', `translate(${margin.left + drawAreaWidth}, 0)`)
      .call(
        d3.axisLeft(yScale)
          .ticks(null, 's')
          .tickSize(drawAreaWidth),
      )
      .call(g => g.selectAll('.domain').remove())
      .call(g => g.selectAll('.tick:not(:first-of-type) line')
        .attr('stroke-opacity', 0.2)
        .attr('stroke-dasharray', '2,2'))
      .call(g => g.selectAll('.tick:first-of-type line')
        .attr('opacity', 0.3),
      )
      .call(g => g.selectAll('.tick text')
        .attr('color', '#999'),
      )
    this.xAxis = g => g
      .attr('transform', `translate(0, ${height-margin.bottom})`)
      .call(
        d3.axisBottom(x0Scale)
          .ticks()
          .tickFormat(tickFormatter),
      )
      .call(g => g.selectAll('.domain').remove())
      .call(g => g.selectAll('.tick text')
        .attr('color', '#999'),
      )
      .call(g => g.selectAll('.tick line')
        .attr('opacity', '0'),
      )
  }

  appendAxis() {
    const {
      xAxis,
      yAxis,
      svg,
    } = this

    if (this.xAxisG) {
      this.xAxisG.transition()
        .duration(300)
        .ease(d3.easeSin)
        .call(this.xAxis)
    } else {
      this.xAxisG = svg.append('g').call(xAxis)
    }

    if (this.yAxisG) {
      this.yAxisG.transition()
        .duration(300)
        .ease(d3.easeSin)
        .call(this.yAxis)
    } else {
      this.yAxisG = svg.append('g').call(yAxis)
    }
  }

  appendLegend() {
    const { svg } = this

    if (this.legendG) {
      this.legendG.remove()
    }
    this.legendG = svg.append('g').call(this.legend)
  }

  update(data, freq) {
    this.data = data
    this.config.freq = freq
    this.initD3Element()
    this.initAxis()
    this.appendAxis()
    this.prepareBars()
    this.appendLegend()
  }

  render() {
    this.initSvg()
    this.initD3Element()
    this.initAxis()
    this.appendAxis()
    this.prepareBars()
    this.appendLegend()
  }
}

class StackedBarChart extends React.Component {
  static propTypes = {
    data: arrayOf(object),
    colorRange: arrayOf(string),
    freq: string,
    formatMessage: func,
  }
  constructor() {
    super()
    const id = 'group-bar-' + Math.random().toString().slice(2, 10)
    this.state = {
      id, 
      barClass: id + '-bar',
    }
  }
  componentDidMount() {
    this.draw(this.props.data)
  }

  componentDidUpdate() {
    this.draw(this.props.data)
  }

  draw(data) {
    const { formatMessage, freq } = this.props
    const $node = document.getElementById(this.state.id)
    // if ($node.children.length) {
    //   $node.innerHTML = ''
    // }
    if (this.chart) {
      this.chart.update(data, freq)
    } else {
      this.chart = new Chart(
        $node,
        data,
        {
          colorRange: this.props.colorRange,
          barClass: this.state.barClass,
          formatMessage,
          freq,
        },
      )
      this.chart.render()
    }
  }

  render() {
    return (
      <Wrap
        id={ this.state.id }
        style={{ width: '100%' }}
        barClass={ this.state.barClass }
      />
    )
  }
}

export default StackedBarChart
