import { AnnuitySetMArgs } from '@/services/core/AnnuitySet'
import { observable, action } from 'mobx'
import { RootStore } from './RootStore'

export default class StoryTeller {
  constructor(
    public rootStore: RootStore,
  ) {}
  // date for adjust target annuity to achieve target
  @observable public chapters: any = []
  @observable public addedChapterIds: any = []

  @action init(annuitySets: AnnuitySetMArgs[]) {
    this.chapters = annuitySets
  }

  @action next() {
    const idx = this.addedChapterIds.length
    const annuitySet = this.chapters[idx]
    const asid = this.rootStore.annuitySet.create({
      id: annuitySet.id,
      name: annuitySet.name,
      desc: annuitySet.desc,
      holdings: annuitySet.annuities,
    })
    this.addedChapterIds.push(asid)
  }

  @action
  public done() {
    this.reset()
  }

  @action
  public skip () {
    while (this.addedChapterIds.length !== this.chapters.length) {
      this.next()
    }
    this.done()
  }

  reset() {
    this.chapters = []
    this.addedChapterIds = []
  }
}

