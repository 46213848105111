import React, { useState } from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { Sizes } from '@/style/theme'
import { isMobile, storage } from '@/utils'
import { Button } from '@/components'

const Wrap = styled.div`
  position: absolute;
  height: 50px;
  top: ${Sizes.app.headerHeight}px;
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: space-between;
  background-color: #666;
  color: #fff;
  font-size: 3.5vw;
  width: 100%;
  z-index: 100000;
  top: 36px;
`

const DesktopHintShownKey = 'IS_DesktopHintShown'

const DesktopSuggestion = () => {
  const [isActive, setIsActive] = useState(!storage.get(DesktopHintShownKey))
  const { formatMessage } = useIntl()

  if (!isActive) return null
  if (window.innerWidth > Sizes.screen.tablet || !isMobile()) return null
  return (
    <Wrap>
      { formatMessage({ id: 'general.use-desktop.hint' }) }
      <Button size="small" onClick={() => {
        storage.set(DesktopHintShownKey, true)
        setIsActive(false)
      }}>
        { formatMessage({ id: 'general.use-desktop.got-it' }) }
      </Button>
    </Wrap>
  )
}

export default DesktopSuggestion
