// TODO
import cloneDeep from 'lodash/cloneDeep' 
import uniq from 'lodash/uniq' 
import DataPoint from './DataPoint'
import Annuity, { AnnuityFreq } from './Annuity'
import AnnuitySet from './AnnuitySet'
import formatter from './Formatter'

export interface DataPoints {
  [time: string]: DataPoint[]
}

export enum RegisterMode {
  NORMAL = 'NORMAL',
  ABS = 'ABS'
}

export interface RegisterConfig {
  mode?: RegisterMode
  freq?: AnnuityFreq
}

export default class Slots {
  constructor(
    public slotType: AnnuityFreq = AnnuityFreq.TS,
    public dataPoints: DataPoints = {},
  ) {}

  // 參數一定是要 merge 過的 data point
  // 把只有一個 DataPoint 的特例 Slots 做 accumulate
  public static accumulateDataPoint(
    _slots: Slots,
    netAssetGrowthRateAnnual: number,
    shouldIgnoreNegativeGrowth: boolean = false,
  ): Slots {
    const slots = cloneDeep(_slots)
    const keys = Object.keys(slots.dataPoints).sort()
    let acc = 0
    let lastDate = null
    // const growthRate = slots.slotType === AnnuityFreq.MONTH
    //   ? FinMath.annualRateToMonthlyRateCompound(netAssetGrowthRateAnnual)
    //   : netAssetGrowthRateAnnual

    for (const key of keys) {
      const dataPoint = slots.dataPoints[key]
      const {
        currentTime,
        annuity,
      } = dataPoint[0]
      let incrementor

      if (!shouldIgnoreNegativeGrowth || acc + annuity.amount > 0) {
        const yearDiff = currentTime.diffYear(lastDate || currentTime)
        incrementor = acc * Math.pow(1 + netAssetGrowthRateAnnual, yearDiff)
        annuity.amount += incrementor
        acc = annuity.amount
        lastDate = currentTime
      } else {
        // 如果是負的現金流就不算報酬率了
        // incrementor = acc / Math.pow(1 + netAssetGrowthRateAnnual, yearDiff)
        acc += annuity.meta.pureAmount
        annuity.amount = acc
        lastDate = currentTime
      }
    }
    return slots
  }

  /**
   * 兩個 Slots instance 做 dataPoints 的 merge
   */
  public static mergeSlots(
    slot1: Slots,
    slot2: Slots,
  ): Slots {
    if (slot1.slotType !== slot2.slotType) {
      new TypeError('Different slotType is not mergeable')
    }
    const keys1 = Object.keys(slot1.dataPoints)
    const keys2 = Object.keys(slot2.dataPoints)

    const joinedKey = uniq(keys1.concat(keys2))
    const mergedDataPoints: DataPoints = {}

    for (const key of joinedKey) {
      const dataPoint1 = slot1.dataPoints[key]
      const dataPoint2 = slot2.dataPoints[key]
      if (dataPoint1 && dataPoint2) {
        mergedDataPoints[key] = dataPoint1.concat(dataPoint2)
      } else {
        if (dataPoint1) {
          mergedDataPoints[key] = dataPoint1
        }

        if (dataPoint2) {
          mergedDataPoints[key] = dataPoint2
        }
      }

    }

    return new Slots(
      slot1.slotType,
      mergedDataPoints,
    )
  }
  
  public registerAnnuity(annuity: Annuity, config?: RegisterConfig) {
    for (let i = 0; i < annuity.n; i++) {
      const currentTime = annuity.startAt.increment(i, annuity.freq)
      const key = currentTime.getSlotKey({ slotType: this.slotType })
      const dataPoint: DataPoint = new DataPoint(
        config && config.mode === RegisterMode.ABS
          ? annuity.abs()
          : annuity,
        currentTime,
        i + 1,
      )

      if (this.dataPoints[key]) {
        this.dataPoints[key].push(dataPoint)
      } else {
        this.dataPoints[key] = [dataPoint]
      }
    }
  }

  /**
   * 把每個 dataPoints 裡的 每個 dataPoint merge 成單一個 dataPoint
   */
  // No sideEffect
  public _mergeDataPoints(
    netAssetGrowthRateAnnual: number,
    annuityName: string = '',
    freq: AnnuityFreq,
  ): Slots {
    let ret = {}
    const keys = Object.keys(this.dataPoints).sort()
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      const dataPoint = this.dataPoints[key]
      const mergedDataPointList =
        dataPoint.reduce((pre: DataPoint[], cur) => {
          if (cur.annuity.name === 'dummy-annuity-set') return pre
          const { currentTime } = cur
          const multiplier = freq === AnnuityFreq.YEAR
            ? Math.pow(1 + netAssetGrowthRateAnnual, currentTime.distToEndOfYear())
            : Math.pow(1 + netAssetGrowthRateAnnual, currentTime.distToEndOfMonth())
          
          // TODO
          // 1. 淨資產最小值拿掉
          // 2. 加上說明，淨資產是 end of the year
          // const multiplier = 1
          
          if (pre.length) {
            pre[0].annuity.amount += cur.value * multiplier
            pre[0].nth = i + 1
            pre[0].annuity.meta.pureAmount += cur.value
            return pre
          }
          
          const time = freq === AnnuityFreq.YEAR
            ? cur.currentTime.getEndOfYear()
            : cur.currentTime.getEndOfMonth()
          return [
            new DataPoint(
              new Annuity({
                name: annuityName,
                amount: cur.value * multiplier,
                discountRate: 0,
                startAt: time,
                freq: this.slotType,
                n: 1,
                meta: {
                  pureAmount: cur.value,
                },
              }),
              time,
              i + 1,
            ),
          ]
        }, [])
      ret = {
        ...ret,
        [key]: mergedDataPointList,
      }
    }
    return new Slots(this.slotType, ret)
  }

  public registerAnnuitySet(annuitySet: AnnuitySet, config?: RegisterConfig) {
    annuitySet.holdings.forEach((annuity) => {
      this.registerAnnuity(annuity, config)
    })
  }

  public toChartFormat() {
    return formatter.slotsToChartData(this)
  }

  public toChartFormatMergeInEx() {
    const chartData = this.toChartFormat()

    const INCOME_NAME = 'income'
    const EXPENDITURE_NAME = 'expenditure'
    const columns = ['name', 'income', 'expenditure']
    const ret: any = []
    for (const datum of chartData) {
      const keys = Object.keys(datum)
      const newDatum = {
        name: datum.name,
        [INCOME_NAME]: 0,
        [EXPENDITURE_NAME]: 0,
      }
      for (const key of keys) {
        if (key === 'name') continue
        const value = +datum[key] as number
        if (value > 0) {
          newDatum[INCOME_NAME] += value
        } else {
          // NOTICE if the should negate the expends
          newDatum[EXPENDITURE_NAME] -= value
        }
      }
      ret.push(newDatum)
    }
    ret.columns = columns
    return ret
  }
}


