// TODO
// 1. 選 income expenditure 顏色
// 2. 顏色區間產生器
import get from 'lodash/get'

export const grayScale = [
  '#000',
  '#111',
  '#222',
  '#333',
  '#444',
  '#555',
  '#666',
  '#777',
  '#888',
  '#999',
  '#aaa',
  '#bbb',
  '#ccc',
  '#ddd',
  '#eee',
  '#fff',
]

export const mainColors = [
  '#24292e',
  // '#888',
]

// function rotate(arr: any[], pos: number): any[] {
//   return arr.slice(pos, arr.length - 1).concat(arr.slice(0, pos))
// }

export function hexToRgba(hex: string, alpha = 0.5) {
  const result: string = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex) as unknown as string
  return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})`
}

export const lightTheme = {
  
  // chartColorRange: d3.schemePastel1,
  // chartColorRange: rotate(['#FFC857', '#E9724C', '#C5283D', '#481D24', '#255F85'], 5),
  // chartColorRange: rotate(['#66f', '#88f', '#aaf', '#ccf', '#eef'], 1),
  // chartColorRange: rotate(['#40B9B0', '#97D2AE', '#F3E293', '#F1BF61', '#FB4135'], 5),
  /* Gray Scale */ 
  // mainColor: '#108ee9',
  // mainColor: '#E6447D',
  // chartColorRange: rotate(['#333333', '#555555', '#777777', '#999999', '#bbbbbb', '#dddddd'], 0),

  
  /* Slack Purple */ 
  // mainColor: '#812f89',
  // chartColorRange: rotate(['#431E44', '#3F0e40', '#522653', '#350D36', '#250D16', '#070108'], 0) ,
  /* Slack Colorful */ 
  // mainColor: '#108ee9',
  // chartColorRange: rotate(['#E01E5A', '#ECB22E', '#36C5F0', '#2EB67D', '#1264A3', '#611F69', '#454545', '#1D1C1D'], 0),
  // chartColorRange: [hexToRgba('#f27077'), hexToRgba('#ae77b2'), hexToRgba('#6caddf'), hexToRgba('#9dd374'), hexToRgba('#e193bc'), hexToRgba('#da8868'), hexToRgba('#fab16a'), hexToRgba('#929392')],
  chartColorRange: [
    'rgba(46,182,125,0.5)',
    'rgba(242,112,119,0.5)',
    'rgba(108,173,223,0.5)',
    'rgba(250,177,106,0.5)',
    'rgba(157,211,116,0.5)',
    'rgba(225,147,188,0.5)',
    'rgba(178,179,178,0.5)',
    'rgba(218,136,104,0.5)',
    'rgba(146,147,146,0.5)',
    '#6caddf',
  ],
  // chartColorRange: ['#2EB67D', '#92c0df', '#0096C7', '#00B4D8', '#48CAE4', '#90E0EF', '#ADE8F4'],
  // chartColorRange: [hexToRgba('#2EB67D'), hexToRgba('#92c0df'), hexToRgba('#0096C7'), '#00B4D8', '#48CAE4', '#90E0EF', '#ADE8F4'],

  // Slack 綠
  // mainColor: '#2EB67D',
  // mainColor: '#92c0df',
  // mainColor: '#4dc1ea',
  // mainColor: '#6a9bc3',
  mainColor: '#666',
  netAssetChartColor: '#bbbbbb',
  income: '#1EA66D',
  expenditure: '#ec6253',
  // expenditure: '#72a0bf',
  // netAssetChartColor: '#2EB67D',
  
  // Slack 桃紅
  // mainColor: '#E01E5A',

  // Slack 軍藍
  // mainColor: '#1264A3',
  // netAssetChartColor: '#1264A3',

  // headerBgc: mainColors[0],
  headerBgc: '#2a3341',
  // headerBgc: '#fff',

  grayScale,

  // body: '#f5f5f5',
  body: '#fafafb',

  textMain: grayScale[3],
  text2nd: grayScale[6],
  text3rd: grayScale[8],

  separator: grayScale[11],

  paperBg: grayScale[15],
  paperShadow: '0 1px 2px 0 rgba(0,0,0,.2)',

  codeBlock: '#f5f5f5',

  headerText: grayScale[14],

  skillSelected: grayScale[14],
  skillDotInactive: grayScale[13],

  tagBg: grayScale[3],
  tagText: grayScale[14],

  main: 'rgb(0, 139, 139)',
}

type LightThemeType = typeof lightTheme
type LightTheme = typeof lightTheme;


// ct as consumeTheme
export const ct = (path: keyof LightTheme) =>
  ({ theme }: { theme: LightThemeType }) => get(theme, path, '')

  
export const Sizes = {
  app: {
    containerWidth: 1040,
    // containerWidth: '100%',
    storyTellerWidth: 360,
    headerHeight: 36,
  },
  screen: {
    s: 600,
    tablet: 987,
  },
}
