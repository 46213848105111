// add comma
// currency, percentage
// fixed N number?

/**
 * atom:
 * add comma
 * to fixed
 * 
 * append dollar sign
 * 
 * 
 * data-integer, float, null
 * 
 * 
 * 
 */

interface I18nFormatter {
  (locale: string, input: string): string
}

interface I18nFormatter {
  (locale: string, input: number): string
}

export const addComma = (locale: string, input: number) => {
  return input.toLocaleString()
}

export const prependDollarSign = (locale: string, input: string) => {
  return '$ ' + input
}

// TODO: 研究 type script overloading
// export const formatCurrency: I18nFormatter = (locale: string, input: number) => {
export const formatCurrency = (locale: string, input: number, config = { decimalPlace: 2 }) => {
  let v1 = +input.toFixed(config.decimalPlace)
  if (v1 >= 1000000000000) {
    v1 /= 1000000000000
    v1 = Math.round(v1)
    const v2 = addComma(locale, v1)
    return prependDollarSign(locale, v2) + 'T'
  } else if (v1 >= 1000000000) {
    v1 /= 1000000000
    v1 = Math.round(v1)
    const v2 = addComma(locale, v1)
    return prependDollarSign(locale, v2) + 'B'
  }
  const v2 = addComma(locale, v1)
  return prependDollarSign(locale, v2)
}

export const toPercentage = (number: number | string, config = { decimalPlace: 2 }) => {
  return (+number * 100).toFixed(config.decimalPlace) + '%'
}

export const formatStringDateYear = (d: string, idx: number, tickMod: number) => {
  return idx % tickMod === 0 ? d : null
}

export const formatStringDateMonth = (d: string, idx: number, n: number, tickMod: number) => {
  if (n <= 8) {
    return d
  }
  if (n > 48) {
    const dateArr = d.split('-')
    if (dateArr[1] === '01') {
      return +dateArr[0] % selectTickMod(n / 12) === 0 ? d : null
    }
    return null
  } else {
    
    return idx % 4 === 0 ? d : null
  }
}


export const selectTickMod = (num: number) => {
  if (num <= 8 ) {
    return 1
  }
  return Math.ceil(num / 30) * 2
}

// raw number -> currency
// add dollar sign
// add comma
// 
// formatCurrency -> toFixed, addComma, addDollarSign
// formatNumAbbr -> transform
