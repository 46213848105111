import Annuity from './Annuity'
import Time from './Time'

export enum AnnuityType {
  NORMAL = 'NORMAL'
}


export default class DataPoint {
  constructor(
    public annuity: Annuity,
    public currentTime: Time,
    public nth: number,
  ) {
  }

  get value() {
    const {
      amount,
      growthRate,
    } = this.annuity
    return amount * Math.pow(1 + growthRate, this.nth)
  }
}


