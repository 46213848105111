// TODO: refactor from firebase logic
// Notification i18n
import { useEffect, useState } from 'react'

import notification from '@/components/feedback/notification'
import FirestoreStories from '@/firebase/FirestoreStories'
import { StoryMArgs, StoryType } from '../../core/Story'
import { getUserId } from '@/utils'

// should receive query as v
const fetchStoryList: GeneralAPIService<StoryMArgs<ID>[]> = async () => {
  try {
    const querySnapshot = await FirestoreStories.getAll()

    const ret: any = []
    querySnapshot.forEach((doc: any) => {
      ret.push(doc.data())
    })
    return { data: ret }
        
  } catch (e) {
    notification.error({
      message: 'FetchStoryList Failed',
      description: e.message,
    })
    throw new Error(e.message)
  }
}

// should receive query as v
const fetchStoryTemplateList: GeneralAPIService<StoryMArgs<ID>[]> = async () => {
  try {
    const querySnapshot = await FirestoreStories.getAllTemplate()

    const ret: any = []
    querySnapshot.forEach((doc: any) => {
      ret.push(doc.data())
    })
    return { data: ret }
        
  } catch (e) {
    notification.error({
      message: 'FetchStoryTemplateList Failed',
      description: e.message,
    })
    throw new Error(e.message)
  }
}

export const fetchStory: GeneralAPIService<StoryMArgs> = async (sid: string) =>  {
  try {
    const querySnapshot = await FirestoreStories.getOne(sid)

    let data: any = null
    querySnapshot.forEach((doc: any) => {
      data = doc.data()
    })
    if (!data) {
      throw new Error(`Story ${sid} does not exist`)
    }

    return { data }
  } catch (e) {
    notification.error({
      message: `Story ${sid} does not exist`,
      description: e.message,
    })
    throw new Error(e.message)
  }
}

export const createStory: any = async (story: StoryMArgs, userId: string = getUserId()) => {
  try {
    if (!userId)  {
      throw new Error('createStory is not allow without userId')
    }
    if (!story.id)  {
      throw new Error('createStory is not allow without story id')
    }
    const storyPayload = {
      ...story,
      type: StoryType.NORMAL,
      owner: userId,
      lastEditTime: Date.now(),
    }
    await FirestoreStories.create(storyPayload)

    return { data: story.id }

  } catch (e) {
    notification.error({
      message: `Create Failed`,
      description: e.message,
    })
    throw new Error(e.message)
  }
}

export const updateStory: any = async (story: StoryMArgs) => {
  try {
    const userId = getUserId()
    if (!userId)  {
      throw new Error('updateStory is not allow without userId')
    }
    if (!story.id)  {
      throw new Error('updateStory is not allow without story id')
    }
    await FirestoreStories.create({
      ...story,
      type: StoryType.NORMAL,
      owner: userId,
      lastEditTime: Date.now(),
    })

    return { data: story.id }

  } catch (e) {
    notification.error({
      message: `Update Failed`,
      description: e.message,
    })
    throw new Error(e.message)
  }
}

export const deleteStory: any = async (story: StoryMArgs) => {
  try {
    const userId = getUserId()
    if (!userId)  {
      throw new Error('deleteStory is not allow without userId')
    }
    if (!story.id)  {
      throw new Error('deleteStory is not allow without story id')
    }
    await FirestoreStories.delete(story)

    return true

  } catch (e) {
    notification.error({
      message: `Delete Failed`,
      description: e.message,
    })
    throw new Error(e.message)
  }
}
  
export const useFetchStoryList = (cacheKey: any = '00') => {
  const [data, setData] = useState<StoryMArgs<ID>[] | void>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    const fetchData = async () => {
      try {
        const { data } = await fetchStoryList()

        setData(data)
      } catch (error) {
        setIsError(true)
      }

      setIsLoading(false)
    }

    fetchData()
  }, [cacheKey])

  return [ data, isLoading, isError ]
}
  
export const useFetchStoryTemplateList = () => {
  const [data, setData] = useState<StoryMArgs<ID>[] | void>(undefined)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await fetchStoryTemplateList()

        setData(data)
      } catch (error) {
        setIsError(true)
      }

      setIsLoading(false)
    }

    fetchData()
  }, [])

  return [ data, isLoading, isError ]
}

  