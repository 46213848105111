import React from 'react'
import styled from 'styled-components'

const Wrap = styled.div<{ isDanger: boolean }>`
  font-size: 13px;
  color: ${({ isDanger }) => isDanger ? 'red' : '#777' };
  padding-left: 2px;
  margin-top: 2px;
  margin-bottom: -8px;
`


interface Props {
  currentCount: number
  maxCount: number
}

const CharCount = ({
  currentCount,
  maxCount,
}: Props) => {
  return (
    <Wrap isDanger={ currentCount > maxCount }>
      {currentCount} / {maxCount}
    </Wrap>
  )
}

export default CharCount
