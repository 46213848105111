import React from 'react'
import styled from 'styled-components'
import { Sizes, ct } from '@/style/theme'

const Wrap = styled.div`
  background-color: rgba(255,255,255,.2);
  height: 4px;
  overflow: hidden;
  position: fixed;
  width: 100%;
  top: ${Sizes.app.headerHeight}px;
  left: 0;
  z-index: 0;
  .first-indicator, .second-indicator {
    background-color: #55c3f7;
    /* background-color: ${ct('mainColor')}; */
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: absolute;
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transform: translate3d(0, 0, 0) scaleX(0);
    transform: scaleX(0);
    will-change: transform;
  }
  .first-indicator {
    animation: first-indicator 2000ms linear infinite;
  }
  .second-indicator {
    animation: second-indicator 2000ms linear infinite;
  }
`

type Props = {}

export const PageLoading = (props: Props) => (
  <Wrap>
    <div className="first-indicator" />
    <div className="second-indicator" />
  </Wrap>
)


export default PageLoading
