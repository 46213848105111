// This is for root level router
// you can create any nested in the pages of if needed
import React, { Component } from 'react'
import styled from 'styled-components'

import { BrowserRouter, Switch, Route, withRouter } from 'react-router-dom'
import StoryPage from '@/pages/StoryPage/loadable'
import StoryShelf from '@/pages/StoryShelf/loadable'
import StoryShelfIntro from '@/pages/StoryShelfIntro/loadable'
import Login from '@/pages/Login/loadable'
import Signup from '@/pages/Signup/loadable'
import SignupSuccess from '@/pages/SignupSuccess/loadable'
import ForgetPassword from '@/pages/ForgetPassword/loadable'
import ResetPassword from '@/pages/ResetPassword/loadable'
import About from '@/pages/About/loadable'
import Admin from '@/pages/Admin/loadable'
import LanguageProvider, { dangerouslyFormatMessage } from '@/containers/LanguageProvider'
import Header from '@/containers/PageHeader'
import DesktopSuggestion from '@/components/molecule/DesktopSuggestion'

const Main = styled.main``

const MainWrap = styled.div`
  position: relative;
`

const ErrorContainer = styled.div`
  width: 100%;
  margin-top: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
class ScrollToTop extends Component<any> {
  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

const ScrollToTopComp = withRouter(ScrollToTop)

const Router = () => {
  try {
    return (
      <BrowserRouter>
        <ScrollToTopComp>
          <LanguageProvider>
            <DesktopSuggestion />
            <MainWrap>
              <Header />
              <Main>
                {/* <ControlPanel /> */}
                <Switch>
                  {
                    process.env.NODE_ENV === 'development'
                  && <Route
                    path="/admin"
                    render={() => <Admin />}
                    exact
                  />
                  }
                  <Route
                    path="/"
                    render={() => <StoryShelf />}
                    exact
                  />
                  <Route
                    path="/story/:storyId"
                    render={() => <StoryPage />}
                    exact
                  />
                  <Route
                    path="/about"
                    render={() => <About />}
                    exact
                  />
                  <Route
                    path="/story"
                    render={() => <StoryShelf />}
                    exact
                  />
                  <Route
                    path="/login"
                    render={() => <Login />}
                    exact
                  />
                  <Route
                    path="/signup"
                    render={() => <Signup />}
                    exact
                  />
                  <Route
                    path="/signup-success"
                    render={() => <SignupSuccess />}
                    exact
                  />
                  <Route
                    path="/forget-password"
                    render={() => <ForgetPassword />}
                    exact
                  />
                  <Route
                    path="/reset-password"
                    render={() => <ResetPassword />}
                    exact
                  />
                  <Route
                    path="/get-started"
                    render={() => <StoryShelfIntro />}
                    exact
                  />
                </Switch>
              </Main>
            </MainWrap>
          </LanguageProvider>
        </ScrollToTopComp>
      </BrowserRouter>
    )
  } catch (e) {
    console.error('Error: ', e)
    return <ErrorContainer>
      {dangerouslyFormatMessage('error.try-later')}
    </ErrorContainer>
  }
}

export default Router
