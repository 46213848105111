import React from 'react'
import RawModal from 'antd/es/modal'
import { useIntl } from 'react-intl'

type Props = any

function Modal ({ children, ...props }: Props) {
  const { formatMessage } = useIntl()
  return <RawModal
    okText={formatMessage({ id: 'confirm' })}
    cancelText={formatMessage({ id: 'cancel' })}
    {...props}
  >
    { children }
  </RawModal>
}

Modal.confirm = RawModal.confirm

export default Modal