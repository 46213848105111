import React, { useEffect } from 'react'
import moment from 'moment'
import get from 'lodash/get'
import { IntlProvider } from 'react-intl'
/* eslint-disable-next-line */
import { withRouter, RouteComponentProps, match } from 'react-router';

import enJson from '@/i18n/en.json'
import zhJson from '@/i18n/zh.json'
import { en, zh } from '@/i18n/workaround'
import { getUserLocale } from '@/utils'

export type NormalizeLocaleType = 'en' | 'zh'

const workAroundMessage: any = {
  en,
  zh,
}

const messages: {
  [key:string]: {}
} = {
  en: enJson,
  zh: zhJson,
}

const LANGUAGES = [
  'zh',
  'en',
]

export const DEFAULT_LANG = LANGUAGES[0]

export type PathParamsType = {
  lang: string,
}

export function normalizeLocale(locale: string): NormalizeLocaleType {
  const lang = locale.split('-')[0] as NormalizeLocaleType
  return LANGUAGES.indexOf(lang) !== -1
    ? lang
    : DEFAULT_LANG as NormalizeLocaleType
}

export function getNormalizedUserLocale(): NormalizeLocaleType {
  return normalizeLocale(getUserLocale())
}

function resolveLocale(locale: string) {
  const normalizedLocale = normalizeLocale(locale)
  if (LANGUAGES.includes(normalizedLocale)) {
    return normalizedLocale
  }
  return DEFAULT_LANG
}

type Props = RouteComponentProps<PathParamsType> & {
  children: React.ReactNode
}

let currentLocale = DEFAULT_LANG

const LanguageProvider: React.FunctionComponent<Props> = ({
  children,
  match,
}: Props) => {
  const locale = resolveLocale(getUserLocale())
  
  currentLocale = locale
  useEffect(() => {
    moment.locale(locale)
    moment.updateLocale(locale, {
      relativeTime : {
        future: dangerouslyFormatMessage('moment.relative.time.future'),
        past: dangerouslyFormatMessage('moment.relative.time.past'),
        s: dangerouslyFormatMessage('moment.relative.time.s'),
        ss: dangerouslyFormatMessage('moment.relative.time.ss'),
        m: dangerouslyFormatMessage('moment.relative.time.m'),
        mm: dangerouslyFormatMessage('moment.relative.time.mm'),
        h: dangerouslyFormatMessage('moment.relative.time.h'),
        hh: dangerouslyFormatMessage('moment.relative.time.hh'),
        d: dangerouslyFormatMessage('moment.relative.time.d'),
        dd: dangerouslyFormatMessage('moment.relative.time.dd'),
        M: dangerouslyFormatMessage('moment.relative.time.M'),
        MM: dangerouslyFormatMessage('moment.relative.time.MM'),
        y: dangerouslyFormatMessage('moment.relative.time.y'),
        yy: dangerouslyFormatMessage('moment.relative.time.yy'),
      },
    })

  }, [])
  return <IntlProvider
    locale={ locale }
    messages={messages[locale]}

  >
    { children }
  </IntlProvider>
}

export const dangerouslyFormatMessage = (key: string, variables: Record<string, string> = {}) => {
  const msg = get(workAroundMessage[currentLocale], key, key)
  const vars = Object.keys(variables)
  return vars.reduce((pre, cur) => {
    return pre.replace(`{${cur}}`, variables[cur])
  }, msg)
}

export default withRouter(LanguageProvider)
