
import app from '../FirebaseCore'
import 'firebase/auth'

class FirebaseAuth {
  public auth: any;
  public googleProvider: any;
  constructor() { 
    this.auth = app.auth()
    // TODO: i18n
    // this.auth.languageCode = get(this.auth, 'currentUser.oa', null) || window.navigator.language
    this.auth.languageCode = 'zh-TW'
    this.googleProvider = new app.auth.GoogleAuthProvider()
  }
 
  // *** Auth API ***
 
  doCreateUserWithEmailAndPassword = (email: string, password: string) =>
    this.auth.createUserWithEmailAndPassword(email, password);
 
  doSignInWithEmailAndPassword = async (email: string, password: string) =>
    this.auth.signInWithEmailAndPassword(email, password);
 
  doSignOut = () => this.auth.signOut();
 
  doPasswordReset = (email: string) => this.auth.sendPasswordResetEmail(email);
 
  doPasswordUpdate = (password: string) =>
    this.auth.currentUser.updatePassword(password);

  doGoogleLogin = () =>
    this.auth.signInWithRedirect(this.googleProvider)
}


 
export default new FirebaseAuth()