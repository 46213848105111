import React, { useCallback } from 'react'
import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import { NavLink as RawNavLink, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { logout } from '@/services/api/auth'

import { Navbar, Popover } from '@/components'
import { Sizes, ct } from '@/style/theme'
import { useStore } from '@/store/useStore'
import ProfileSection from './components/ProfileSection'

const Wrap = styled.div`
  /* max-width: ${Sizes.app.containerWidth}px; */
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Brand = styled.span`
  letter-spacing: 0.5px;
  font-weight: bold;
  color: #fff;
`
const Version = styled.span`
  font-size: 12px;
  margin-left: -8px;
  margin-right: 40px;
  background-color: ${ct('mainColor')};
  display: inline-block;
  padding: 0 6px;
  color: #fff;
  border-radius: 2px;
  cursor: help;
`

const Left = styled.div`
  padding-left: 5px;
`

const Right = styled.div`
  padding-right: 15px;
`

const NavLink = styled(RawNavLink).attrs({
  activeStyle: { fontWeight: 'bold', color: '#fff' },
})`
  padding: 0 10px;
  color: #e5e5e5;
  /* color: ${ct('textMain')}; */
`

const Header = () => {
  const { authState, me } = useStore()
  const { formatMessage } = useIntl()
  const { push } = useHistory()
  const { isAuth } = authState

  const handleLogout = useCallback(() => {
    logout({ formatMessage })
      .then(() => {
        authState.unauth()
        push('/login')
        window.location.reload()
      })
  }, [authState, formatMessage, push])

  return (
    <Navbar>
      <Wrap>
        <Left>
          <NavLink to={
            isAuth
              ? '/story'
              : '/get-started'
          }>
            <Brand>visify</Brand>
          </NavLink>
          <Popover
            placement="topLeft"
            mouseEnterDelay={0.3}
            title={ formatMessage({ id: 'nav.alpha.title' }) }
            content={
              <p>
                { formatMessage({ id: 'nav.alpha.description' }) }
                {/* <br />
                <Link to="/about">
                  { formatMessage({ id: 'known.issue' }) }
                </Link> */}
              </p>
            }
          >
            <Version>
              alpha
            </Version>
          </Popover>
          {
            isAuth
              ? <>
                <NavLink to="/story">
                  { formatMessage({ id: 'nav.story' }) }
                </NavLink>
                <NavLink to="/about">
                  { formatMessage({ id: 'nav.about-visify' }) }
                </NavLink>
              </>
              : <>
                <NavLink to="/get-started">
                  { formatMessage({ id: 'nav.start-now' }) }
                </NavLink>
                <NavLink to="/about">
                  { formatMessage({ id: 'nav.about-visify' }) }
                </NavLink>
              </>
          }
        </Left>
        <Right>
          {
            isAuth
              ? <ProfileSection
                userInfo={me.userInfo}
                handleLogout={handleLogout}
              />
              : <>
                <NavLink to="/login">
                  { formatMessage({ id: 'login' }) }
                </NavLink>
                <NavLink to="/signup">
                  { formatMessage({ id: 'signup' }) }
                </NavLink>
              </>
          }
        </Right>
      </Wrap>
    </Navbar>
  )
}

export default observer(Header)
