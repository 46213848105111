import React from 'react'
import RawDatePicker from 'antd/es/date-picker'
import zh_TW from 'antd/es/date-picker/locale/zh_TW'
import en_US from 'antd/es/date-picker/locale/en_US'

interface Props {
  locale: string
  [key: string]: any
}

const localeMap: { [key: string]: any } = {
  en: en_US,
  zh: zh_TW,
}

const DatePicker = ({
  locale,
  ...props
}: Props) => (
  <RawDatePicker
    { ...props }
    locale={ localeMap[locale] }
  />
)

export default DatePicker