import React from 'react'
import Tooltip from '../Tooltip'
import Popover from '../Popover'
import { QuestionCircleOutlined } from '../Icons'

interface Props {
  helperBlock: React.ReactNode
  type?: HelpHintType
  placement?: any
}

enum HelpHintType {
  TOOLTIP,
  POPOVER
}

const HelpHint = ({
  helperBlock,
  placement = 'top',
  type = HelpHintType.TOOLTIP,
}: Props) => type === HelpHintType.TOOLTIP
  ? (
    <Tooltip
      placement={ placement }
      title={ helperBlock }
      style={{ fontSize: 14 }}
    >
      <QuestionCircleOutlined
        style={{ cursor: 'pointer', margin: '0 4px', color: '#666', fontSize: 13 }}
        translate="1"
      />
    </Tooltip>
  )
  : (
    <Popover
      placement={ placement }
      content={ helperBlock }
    >
      <QuestionCircleOutlined
        style={{ cursor: 'pointer', margin: '0 4px', color: '#666', fontSize: 13 }}
        translate="1"
      />
    </Popover>
  )
  
HelpHint.HelpHintType = HelpHintType

export default HelpHint