import { v4 as uuidv4 } from 'uuid'
import Time from './Time'

export enum AnnuityType {
  NORMAL = 'NORMAL'
}

export enum AnnuityFreq {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  DAY = 'DAY',
  TS = 'TS'
}

export interface AnnuityMArgs {
  id?: string
  amount: number
  n: number
  freq: AnnuityFreq
  startAt: string
  meta?: any
}

export interface AnnuityCArgs {
  type?: AnnuityType
  name?: string
  desc?: string
  amount: number,
  discountRate: number,
  startAt: Time,
  freq: AnnuityFreq,
  n: number, // 有幾期
  growthRate?: number,
  meta?: any,
}

export default class Annuity {
  public readonly id: number | string
  public type: AnnuityType
  public name: string
  public desc: string
  public amount: number
  public discountRate: number
  public startAt: Time
  public freq: AnnuityFreq
  public n: number // 有幾期
  public growthRate: number
  public meta: any
  constructor(
    {
      type = AnnuityType.NORMAL,
      name = '',
      desc = '',
      amount,
      discountRate,
      startAt,
      freq,
      n,
      growthRate = 0,
      meta = {},
    }: AnnuityCArgs,
  ) {
    this.id = uuidv4()
    this.type = type
    this.name = name
    this.desc = desc
    this.amount = amount
    this.discountRate = discountRate
    this.growthRate = growthRate
    this.startAt = startAt
    this.freq = freq
    this.n = n
    this.meta = meta
  }

  get absAmount() {
    return Math.abs(this.amount)
  }

  abs(): Annuity {
    return {
      ...this,
      amount: Math.abs(this.amount),
    }
  }
}


