import React from 'react'
import styled from 'styled-components'
import { ct, Sizes } from '@/style/theme'

const HEADER_HEIGHT = Sizes.app.headerHeight

const Wrap = styled.header`
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  background-color: ${ct('headerBgc')};
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
`

const Placeholder = styled.div`
  height: ${HEADER_HEIGHT}px;
`

interface Props {
  children: React.ReactNode
}

const Navbar = ({ children }: Props) => {
  return (
    <div>
      <Wrap>
        { children }
      </Wrap>
      <Placeholder />
    </div>
  )
}


export default Navbar
