export const OfficialStory: GeneralObj = {
  // DEMO-TMP
  'retire-low-slug': true,
  'retire-simple-zh': true,
  'retire-wealthy-zh': true,
  'zh-retire-simple': true,
  'zh-retire-well-of': true,
}

export const MAX_NUM = 10000000000000
export const MIN_NUM = -10000000000000

export const AnnuityAmountConstraint = {
  MIN: 1,
  MAX: 10000000,
  MAX_PERIOD: 960,
}
export const AnnuitySliderConstraint = {
  MIN: 0,
  MAX: 500000,
  STEP: 1000,
}

export const FeatureEnabled = {
  // NET_ASSETS_FREQ_SWITCH: false,
  NET_ASSETS_FREQ_SWITCH: true,
}

export const ANNUITY_SET_CAP = 10
export const STORY_CAP = 10

export const TEMPLATE_DESC_SEPARATOR = '--------------'
